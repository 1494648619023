const RolesService = (() => {
    // Function to get user data from localStorage
    const getRolesData = () => {
        const rolesData = localStorage.getItem("roles");
        return rolesData ? JSON.parse(rolesData) : null;
    };

    // Function to set user data into localStorage after login
    const setRolesData = (roles) => {
        localStorage.setItem("roles", JSON.stringify(roles));
    };

    // Function to clear user data (for logout)
    const clearRolesData = () => {
        localStorage.removeItem("roles");
    };

    return {
        getRolesData,
        setRolesData,
        clearRolesData,
    };
})();

// Make it globally accessible
export default RolesService;
