class Helper {
    /**
     * Format a number into currency format
     * @param {number} amount - The amount to format
     * @param {string} currency - The currency code (e.g., 'USD', 'EUR', 'TZS')
     * @param {string} locale - The locale format (e.g., 'en-US', 'fr-FR')
     * @returns {string} - Formatted currency string
     */
    static currencyFormatter(amount, currency = "USD", locale = "en-US") {
        return new Intl.NumberFormat(locale, {
            style: "currency",
            currency: currency,
        }).format(amount);
    }

    /**
     * Generate a random unique ID
     * @returns {string} - Random UUID
     */
    static generateUniqueId() {
        return Math.random().toString(36).substr(2, 9);
    }

    /**
     * Capitalize the first letter of a string
     * @param {string} str - Input string
     * @returns {string} - Capitalized string
     */
    static capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    /**
     * Format a date into a readable format
     * @param {string|Date} date - Date string or Date object
     * @param {string} locale - Locale format (default: 'en-US')
     * @returns {string} - Formatted date string
     */
    static formatDate(date, locale = "en-US") {
        return new Date(date).toLocaleDateString(locale, {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    }
}

export default Helper;
