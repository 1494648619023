import React, { useState, useEffect } from 'react';
import './RightSideBar.css';
import Logo from '../.././../../assets/logo_home.png'
import { useNavigate } from 'react-router-dom';
const RightSideBar = () => {
    const navigate = useNavigate()
  const [ads, setAds] = useState([
    {
      id: 1,
      title: "Hobbee Referral Program",
      description: "Join our referral program and earn by simply our link with your friends",
      imageUrl: Logo,
      company: "BeeHive.inc",
      cta: "See More"
    },
   
  ]);

  return (
    <div className="sidebar-ads-container">
      <div className="sponsored-section">
        <h2 className="section-title">Sponsored</h2>
        
        {ads.map((ad) => (
          <div key={ad.id} className="ad-card">
            <div className="ad-content">
              <img 
                src={ad.imageUrl} 
                alt={ad.title} 
                className="ad-image"
              />
              
              <div className="ad-details">
                <span className="company-name">{ad.company}</span>
                <h3 className="ad-title">{ad.title}</h3>
                <p className="ad-description">{ad.description}</p>
                
                <button className="cta-button" onClick={()=>navigate("/creator/referral")}>
                  {ad.cta}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* <div className="related-artists-section">
        <h3 className="section-title">Related Artists</h3>
        <div className="artists-list">
          {[1, 2, 3].map((item) => (
            <div key={item} className="artist-item">
              <img 
                src={`/api/placeholder/40/40`}
                alt={`Artist ${item}`}
                className="artist-avatar"
              />
              <div className="artist-info">
                <p className="artist-name">Artist Name</p>
                <p className="artist-specialty">Acrylic Painting</p>
              </div>
            </div>
          ))}
          <button className="discover-button">
            Discover More
          </button>
        </div>
      </div> */}
    </div>
  );
};
export default RightSideBar;