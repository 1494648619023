import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import Logo from "../../../assets/logo_home.png"; // Replace with the correct path
import { BASE_URL } from "../../../api/core";
import "./Registration.css"; // Ensure CSS is properly defined
import { UserContext } from "../../../context";

const Registration = () => {
  const { code } = useParams();
  const { userTypeDetalis } = useContext(UserContext);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    retype_password: "",
    latitude: 0.0,
    longitude: 0.0,
  });

  const [passwordStrength, setPasswordStrength] = useState({
    score: 0,
    hasMinLength: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
    hasSpecialChar: false,
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();


  useEffect(() => {
    //check user type
    if (!localStorage.getItem("user_type")) {
      localStorage.setItem("user_type", "normal");
    }

    setReferralLinkIsClicked();
  }, []);

  useEffect(() => {
    // Check password strength whenever password changes
    checkPasswordStrength(formData.password);
  }, [formData.password]);

  const errorMsg = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
      category: userTypeDetalis?.category,
      business_name: userTypeDetalis?.businessName,
      user_type: userTypeDetalis?.userType,
      referral_code: code
    });
  };

  const checkPasswordStrength = (password) => {
    // Check various criteria
    const hasMinLength = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password);

    // Calculate score (0-4)
    let score = 0;
    if (hasMinLength) score++;
    if (hasUpperCase) score++;
    if (hasLowerCase) score++;
    if (hasNumber) score++;
    if (hasSpecialChar) score++;

    // Update state
    setPasswordStrength({
      score: Math.min(4, score), // Max score is 4
      hasMinLength,
      hasUpperCase,
      hasLowerCase,
      hasNumber,
      hasSpecialChar,
    });
  };

  const getStrengthText = () => {
    const { score } = passwordStrength;
    if (score === 0) return "Very Weak";
    if (score === 1) return "Weak";
    if (score === 2) return "Fair";
    if (score === 3) return "Good";
    return "Strong";
  };

  const getStrengthColor = () => {
    const { score } = passwordStrength;
    if (score === 0) return "#ff4d4f"; // Red
    if (score === 1) return "#ff7a45"; // Orange
    if (score === 2) return "#ffc53d"; // Yellow
    if (score === 3) return "#73d13d"; // Light Green
    return "#52c41a"; // Green
  };

  const validate = () => {
    let errors = {};
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
    }
    if (!formData.password) {
      errors.password = "Password is required";
    } else if (passwordStrength.score < 3) {
      errors.password = "Password is not strong enough";
    }
    if (formData.password !== formData.retype_password) {
      errors.retype_password = "Passwords do not match";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      const hideLoading = message.loading("Registration in progress...", 0);

      try {
        const response = await axios.post(`${BASE_URL}/register`, formData);
        if (response) {
          const { token, user } = response.data;

          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("token", token);

        
          navigate("/add-details");
        }
      } catch (error) {
        if (error.response) {
          const errorString = Object.keys(error.response.data.error)
            .map((key) => error.response.data.error[key])
            .join("\n");
          errorMsg(errorString);
        } else {
          alert("Registration failed. Please try again later.");
        }
      } finally {
        hideLoading();
      }
    }
  };

  //track user referral click to see how many users who clicked the link have registered
  const setReferralLinkIsClicked = async () => {
    const token = localStorage.getItem("token");

    if (!token) return;

    const headers = {
      Authorization: `Bearer ${token}`
    };

    try {
      const response = await
        axios.post(`${BASE_URL}/referral/clicks`, { referral_code: code }, { headers });


    } catch (error) {
      console.error("Error fetching referral data", error);
    } finally {
    }
  };

  return (
    <div style={styles.container}>
      {contextHolder}
      {/* Left Section */}
      <div style={styles.leftSection}>
        <img src={Logo} alt="Hobbee Logo" style={styles.logo} />
        <h1 style={styles.title}>Hobbee</h1>
        <p style={styles.subtitle}>Pursue Your Passion</p>
      </div>

      {/* Right Section */}
      <div style={styles.rightSection}>
        <form onSubmit={handleSubmit} style={styles.form}>
          <h2 style={styles.formTitle}>Create an Account</h2>
          <div style={styles.inputGroup}>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              style={styles.input}
            />
            {errors.email && (
              <span style={{ ...styles.errorText, ...styles.centerError }}>
                {errors.email}
              </span>
            )}
          </div>
          <div style={styles.inputGroup}>
            <div style={styles.passwordInputContainer}>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="New Password"
                style={styles.passwordInput}
              />
              <div
                style={styles.passwordToggle}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
              </div>
            </div>
            {formData.password && (
              <div style={styles.passwordStrength}>
                <div style={styles.strengthBar}>
                  {[0, 1, 2, 3].map((index) => (
                    <div
                      key={index}
                      style={{
                        ...styles.strengthSegment,
                        backgroundColor:
                          passwordStrength.score > index
                            ? getStrengthColor()
                            : "#e8e8e8",
                      }}
                    />
                  ))}
                </div>
                <div style={{ ...styles.strengthText, color: getStrengthColor() }}>
                  {getStrengthText()}
                </div>
              </div>
            )}
            {formData.password && (
              <div style={styles.passwordRequirements}>
                <div style={{
                  ...styles.requirement,
                  color: passwordStrength.hasMinLength ? "#52c41a" : "#999"
                }}>
                  ✓ At least 8 characters
                </div>
                <div style={{
                  ...styles.requirement,
                  color: passwordStrength.hasUpperCase ? "#52c41a" : "#999"
                }}>
                  ✓ At least 1 uppercase letter
                </div>
                <div style={{
                  ...styles.requirement,
                  color: passwordStrength.hasLowerCase ? "#52c41a" : "#999"
                }}>
                  ✓ At least 1 lowercase letter
                </div>
                <div style={{
                  ...styles.requirement,
                  color: passwordStrength.hasNumber ? "#52c41a" : "#999"
                }}>
                  ✓ At least 1 number
                </div>
                <div style={{
                  ...styles.requirement,
                  color: passwordStrength.hasSpecialChar ? "#52c41a" : "#999"
                }}>
                  ✓ At least 1 special character
                </div>
              </div>
            )}
            {errors.password && (
              <span style={{ ...styles.errorText, ...styles.centerError }}>
                {errors.password}
              </span>
            )}
          </div>
          <div style={styles.inputGroup}>
            <div style={styles.passwordInputContainer}>
              <input
                type={showRetypePassword ? "text" : "password"}
                name="retype_password"
                value={formData.retype_password}
                onChange={handleChange}
                placeholder="Retype Password"
                style={styles.passwordInput}
              />
              <div
                style={styles.passwordToggle}
                onClick={() => setShowRetypePassword(!showRetypePassword)}
              >
                {showRetypePassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
              </div>
            </div>
            {errors.retype_password && (
              <span style={{ ...styles.errorText, ...styles.centerError }}>
                {errors.retype_password}
              </span>
            )}
          </div>
          <button
            type="submit"
            style={{
              ...styles.button,
              opacity: passwordStrength.score >= 3 ? 1 : 0.7,
            }}
            disabled={formData.password && passwordStrength.score < 3}
          >
            Sign Up
          </button>
          <p style={styles.termsText}>
            By clicking Sign Up, you agree to our{" "}
            <a
              href="/privacy-policies"
              onClick={(e) => {
                e.preventDefault();
                navigate("/privacy-policies");
              }}
              style={styles.link}
            >
              Terms of Use or Data Policy
            </a>
            .
          </p>
        </form>
        <p style={styles.switchText}>
          Already have an account?{" "}
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
            style={styles.link}
          >
            Log In
          </a>
        </p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    fontFamily: "Arial, sans-serif",
  },
  leftSection: {
    flex: 1,
    backgroundColor: "#FFD700", // Yellow background
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  logo: {
    width: "80px",
    marginBottom: "10px",
  },
  title: {
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  subtitle: {
    fontSize: "16px",
    textAlign: "center",
  },
  rightSection: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  form: {
    width: "80%",
    maxWidth: "400px",
    display: "flex",
    flexDirection: "column",
  },
  formTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
    textAlign: "center",
  },
  inputGroup: {
    marginBottom: "20px",
    textAlign: "left"
  },
  input: {
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  passwordInputContainer: {
    position: "relative",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  passwordInput: {
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    paddingRight: "40px", // Space for the eye icon
  },
  passwordToggle: {
    position: "absolute",
    right: "10px",
    cursor: "pointer",
    color: "#999",
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    padding: "10px",
    fontSize: "16px",
    backgroundColor: "#FFD700",
    color: "#000000",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    transition: "opacity 0.3s",
  },
  errorText: {
    fontSize: "12px",
    color: "red",
    marginTop: "5px",
  },
  centerError: {
    width: "100%",
    textAlign: "center",
  },
  termsText: {
    fontSize: "12px",
    marginTop: "10px",
    textAlign: "center",
  },
  link: {
    color: "#b6a005",
    textDecoration: "none",
  },
  switchText: {
    fontSize: "14px",
    marginTop: "20px",
    textAlign: "center",
  },
  passwordStrength: {
    marginTop: "10px",
  },
  strengthBar: {
    display: "flex",
    height: "5px",
    marginBottom: "5px",
  },
  strengthSegment: {
    flex: 1,
    marginRight: "2px",
    borderRadius: "2px",
  },
  strengthText: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  passwordRequirements: {
    marginTop: "10px",
    fontSize: "12px",
  },
  requirement: {
    marginBottom: "3px",
  }
};

export default Registration;