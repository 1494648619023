import { Mic, Palette, User, Video, Home, Heart, Map } from "lucide-react"
import "./LeftSideBar.css"
import { useContext, useEffect, useState } from "react";
import { BASE_URL } from "../../../../api/core";
import axios from "axios";
import { UserContext } from "../../../../context";
import { Link, useNavigate } from "react-router-dom";
import UserService from "../../../../services/UserService";

export default function LeftSideBar({ setActiveLayout, fromPage, showProfile = true, showHobbies = true }) {
  const { userHobbies, setUserHobbies } = useContext(UserContext)
  const navigate = useNavigate()
  const [user, setUser] = useState(null)

  useEffect(() => {
    const user = UserService.getUserData();
    setUser(user)

  }, [])

  useEffect(() => {
    const fetchHobbies = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await axios.get(`${BASE_URL}/hobbies/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const { hobbies } = response.data;

        //set hobbies globally to be accessed anywhere in the app via context
        setUserHobbies(hobbies)
      } catch (error) {
        console.error("Error fetching hobbies:", error);
      } finally {
      }
    };

    fetchHobbies();
  }, []);

  function formatUrlString(str) {
    //make url look clean when using it to pass addition info
    return str.replaceAll("%20", "-");
  }

  return (
    <div className="profile" >
      {showProfile && <div className="profile__header">
        <div className="profile-bg"></div>
      </div>}

      <div className="profile__content">
        {showProfile && <>
          <div className="profile__avatar-container">
            <div className="profile__avatar">
              {user?.image_url === "https://res.cloudinary.com/dxgmi9qni/image/upload/v1708606794/user_1_ew5bnv.png" ? <h1>{user?.firstname.substr(0, 1)}{user?.lastname.substr(0, 1)}</h1> : <img
                src={user?.image_url}
                alt="Profile"
                className="profile__avatar-img"
              />}


            </div>
          </div>

          <div className="profile__info">
            <h1 className="profile__name">{user?.firstname}{""} {user?.lastname}</h1>
            <p className="profile__handle">@{!user?.username ? <span>Hobby</span> : user?.username}</p>
          </div>

          <nav className="profile__nav">
            <button className="profile__nav-button" onClick={() => {
              navigate("/main/profile");
            }}>
              <User className="profile__nav-icon" />
            </button>
            {/* check if sidebar is shown on user home page or hobby home page */}
            {fromPage === "home" && <button className="profile__nav-button" onClick={
              () => {
                setActiveLayout("map")
              }
            }>
              <Map className="profile__nav-icon" />
            </button>
            }
            {fromPage === "home" && <button className="profile__nav-button" onClick={
              () => setActiveLayout("hobbies")
            }>
              <Heart className="profile__nav-icon" />
            </button>
            }
            <button className="profile__nav-button" onClick={
              () => {
                // if page is from hobby home page use navigate
                //if it is from user home page set active layout
                setActiveLayout("posts")

                if (fromPage !== "home") {
                  navigate("/main/account");
                }

              }
            }>
              <Home className="profile__nav-icon" />
            </button>
          </nav>
        </>}
        {showHobbies &&
          <section className="profile__hobbies">
            <h2 className="profile__hobbies-title">Your Hobbies</h2>
            <div className="profile__hobbies-list">
              {userHobbies?.map((hobby, index) => {
                return (
                  <div className="hobby-card" key={index} onClick={() => { navigate(`/main/account/hobby/${hobby.name}`, { state: { data: hobby } }) }}>
                    <img
                      className="hobby-card__icon"
                      style={{ width: 26, height: 26 }}
                      src={`${hobby.icon}`}
                    />
                    <span className="hobby-card__text">{hobby.name}</span>
                  </div>)

              })}
            </div>
          </section>}
      </div>
    </div>
  )
}

