import React, { useState, useRef, useEffect, useContext } from 'react';
import axios from 'axios';
import { Modal, Button, Select, Input, Space, Spin, message } from 'antd';
import { X, Image, Video, Paperclip, Trash } from 'lucide-react';
import './PostCreator.css';
import { BASE_URL } from '../../api/core';
import HobbyService from '../../services/HobbyService';
import { UserContext } from '../../context';
import UserService from '../../services/UserService';
import { useNavigate } from 'react-router-dom';

const { TextArea } = Input;

const PostCreator = ({ visible, onClose }) => {
    const { newUserPost, setNewUserPost } = useContext(UserContext)
    const navigate = useNavigate();

    const [content, setContent] = useState('');
    const [privacy, setPrivacy] = useState('public');
    const [hobby, setHobby] = useState(null);
    const [mediaFile, setMediaFile] = useState(null); // Only one file
    const [isUploading, setIsUploading] = useState(false);
    const [hobbies, setHobbies] = useState([]);
    const [mediaType, setMediaType] = useState("");
    const [user, setUser] = useState(null)


    const imageInputRef = useRef(null);
    const videoInputRef = useRef(null);

    useEffect(() => {
        const hobbies = HobbyService.getHobbyData();
        setHobbies(hobbies);
    }, []);

    useEffect(() => {
        const user = UserService.getUserData();
        setUser(user)
    }, [])
    // Handle file selection
    const handleFileSelect = (event) => {
        const file = event.target.files[0]; // Only allow one file at a time
        if (!file) return;

        const fileType = file.type.startsWith("image") ? "image" :
            file.type.startsWith("video") ? "video" : "file";

        setMediaType(fileType);

        // Store only one file
        setMediaFile({
            file,
            preview: URL.createObjectURL(file),
            type: fileType,
        });

        // Reset file input so same file can be selected again if needed
        event.target.value = "";
    };

    // Remove selected file
    const removeFile = () => {
        setMediaFile(null);
        setMediaType("");
    };

    // Handle Post Submission
    const handlePost = async () => {

        // Validation checks
        if (!hobby?.id) {
            message.warning("Please select a hobby before posting.");
            return;
        }

        if (!privacy) {
            message.warning("Please select a privacy setting.");
            return;
        }

        if (!content.trim()) {
            message.warning("Please add some content before posting.");
            return;
        }

        setIsUploading(true);
        let uploadedMedia = null;

        if (mediaFile) {
            const url = await uploadToCloudinary(mediaFile.file, mediaFile.type);
            if (url) {
                uploadedMedia = { url, type: mediaFile.type };
            }
        }

        await savePost(uploadedMedia);
    };


    // Upload media to Cloudinary
    const uploadToCloudinary = async (file, type) => {
        const data = new FormData();
        data.append("file", file);
        data.append("cloud_name", "dxgmi9qni");
        data.append("upload_preset", "hobbie_app");

        try {
            const cloudinaryUrl = type === 'video'
                ? "https://api.cloudinary.com/v1_1/dxgmi9qni/video/upload"
                : "https://api.cloudinary.com/v1_1/dxgmi9qni/image/upload";

            const response = await axios.post(cloudinaryUrl, data);
            return response.data.secure_url;
        } catch (error) {
            console.error("Cloudinary upload failed:", error);
            return null;
        }
    };

    // Save Post to Backend
    const savePost = async (uploadedMedia) => {
        const token = localStorage.getItem("token");
        /*
            Add data to the feedlist to show user that data has been added
            when in reality its been processed in the background
            structure the object to resemble the object returned from a succeful request from the server 
        */
        setNewUserPost({
            content,
            title: "No title",
            user_id: user?.id,
            hobby_id: hobby.id || null,
            visibility: privacy,
            image_url: uploadedMedia?.url,
            media_type: mediaType || "no_media",
            time_ago: "Just now",
            hobbies: {
                name: hobby?.name
            },
            comments: [],
            likes: [],
            bookmarks: [],
            users: user,
            isSaved: false
        })

        //add object to the server
        const data = {
            content,
            title: "No title",
            user_id: user?.id,
            hobby_id: hobby?.id || null,
            visibility: privacy,
            image_url: uploadedMedia?.url,
            media_type: mediaType || "no_media",
            isSaved: false
        };


        setIsUploading(false);
        onClose();

        try {
            const response = await axios.post(`${BASE_URL}/posts`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });


            message.success("Post created successfully!");
            const { feed } = response.data;

            //update new post with the one from database
            setNewUserPost(feed)

            // Clear fields after successful upload
            setContent("");
            setHobby(null);
            setMediaFile(null);
            setPrivacy("public");
            setMediaType("");
            setNewUserPost(null)

        } catch (error) {
            console.error("Error saving post:", error);
            message.error(`Error: ${error.response?.data?.message || "Failed to save post"}`);
        } finally {
           navigate("/main/account")
        }
    };



    return (
        <Modal
            title="Create Post"
            open={visible}
            onCancel={onClose}
            footer={null}
            centered
        >
            <Spin spinning={isUploading}>
                {/* Dropdowns */}
                <Space style={{ width: '100%', marginBottom: 16 }}>
                    <Select value={privacy} onChange={setPrivacy} style={{ width: 120 }}>
                        <Select.Option value="public">Public</Select.Option>
                        <Select.Option value="friends">Friends</Select.Option>
                        <Select.Option value="private">Private</Select.Option>
                    </Select>

                    <Select
                        value={hobby?.id} // Set value to the hobby ID
                        onChange={(value) => {
                            const selectedHobby = hobbies.find((h) => h.id === value); // Find the full hobby object
                            setHobby(selectedHobby);
                        }}
                        style={{ width: 150 }}
                        placeholder="Select Hobby"
                    >
                        {hobbies?.map((hobby) => (
                            <Select.Option key={hobby.id} value={hobby.id}>
                                {hobby.name}
                            </Select.Option>
                        ))}
                    </Select>

                </Space>

                {/* Textarea */}
                <TextArea
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    placeholder="What's on your mind?"
                    rows={4}
                />

                {/* Media Buttons */}
                <div className="media-buttons">
                    <span>Add to your post</span>
                    <Button disabled={mediaFile} onClick={() => imageInputRef.current.click()}>
                        <Image size={20} color="#10b981" />
                    </Button>
                    <Button disabled={mediaFile} onClick={() => videoInputRef.current.click()}>
                        <Video size={20} color="#3b82f6" />
                    </Button>
                </div>

                {/* Hidden File Inputs */}
                <input type="file" accept="image/*" ref={imageInputRef} style={{ display: 'none' }} onChange={handleFileSelect} />
                <input type="file" accept="video/*" ref={videoInputRef} style={{ display: 'none' }} onChange={handleFileSelect} />

                {/* Preview Uploaded File */}
                {mediaFile && (
                    <div className="uploaded-files">
                        <p>Uploaded File:</p>
                        <div className="preview-container">
                            <div className="preview-item">
                                {mediaFile.type === 'image' && <img src={mediaFile.preview} alt="Preview" className="preview-image" />}
                                {mediaFile.type === 'video' && (
                                    <video className="preview-video" controls>
                                        <source src={mediaFile.preview} type={mediaFile.file.type} />
                                    </video>
                                )}
                                <button className="remove-btn" onClick={removeFile}>
                                    <Trash size={16} color="red" />
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Footer */}
                <div className="post-creator-footer">
                    <Button type="primary" style={{ background: "#ffd700", color: "#000" }} block onClick={handlePost} disabled={isUploading}>
                        {isUploading ? "Uploading..." : "Post"}
                    </Button>
                </div>
            </Spin>
        </Modal>
    );
};

export default PostCreator;

