import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import Home from "./pages/home/Home";
import Login from "./pages/Auth/Login/Login";
import Registration from "./pages/Auth/Registration/Registration";
import VerifyUser from "./pages/Auth/VerifyUser/VerifyUser";
import { UserProvider } from "./context";
import AddHobbies from "./pages/admin/AddHobbies";
import UploadHobbieIcons from "./pages/admin/UploadHobbieIcons";
import UserPolicy from "./pages/privacy/UserPolicy/UserPolicy";
import AddAvatar from "./pages/Auth/AddAvatar/AddAvatar";
import AddDetails from "./pages/Auth/AddDetails/AddDetails";
import UserDeletion from "./pages/privacy/UserDeletion/UserDeletion";
import SelectHobbyList from "./pages/Auth/SelectHobby/SelectHobbyList";
import UserSupport from "./pages/support/UserSupport";
import SendEmailLink from "./pages/Auth/forgotpassword/SendEmailLink";
import VerifyResetCode from "./pages/Auth/forgotpassword/VerifyResetCode";
import ResetPassword from "./pages/Auth/forgotpassword/ResetPassword";
import Dashboard from "./pages/admin/Dashboard/Dashboard";
import HomeBusiness from "./pages/home/Business/HomeBusiness";
import AdvertiserDashboard from "./pages/home/Business/AdsManager/AdvertiserDashboard/AdvertiserDashboard";
import CreateAdPage from "./pages/home/Business/AdsManager/CreateAdPage/CreateAdPage";
import HomeCreatorePage from "./pages/home/influencer/HomeCreatorPage";
import UserUnAuthProfile from "./pages/admin/Dashboard/UserProfile/UserUnAuthProfile";
import ProfileSettings from "./pages/home/UserProfile/ProfileSettings";
import HomeHobbyPage from "./pages/home/hobbies/HomeHobbyPage";
import ReferralDashboard from "./pages/home/influencer/referal/ReferralDashboard";
import AccountRegistration from "./pages/Auth/Registration/AccountRegistration";
import AccountTypesPage from "./pages/Auth/AccountTypes/AccounTypesPage";
import UserValidationPage from "./pages/Auth/Validation/UserValidationPage";
import SuccessValidationPage from "./pages/Auth/Validation/SuccessValidationPage";
import RedirectToStripe from "./pages/payment/RedirectToStripe";
import OnboardingRefresh from "./pages/payment/OnboardingRefresh";
import OnboardingSuccess from "./pages/payment/OnboardingSuccess";

function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Define event handlers for online and offline events
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    // Attach event listeners
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []); // Run once on component mount

  return (
    <div className="app">
      <UserProvider>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="send-email" element={<SendEmailLink />}></Route>
          <Route path="verify-reset-code" element={<VerifyResetCode />}></Route>
          <Route path="reset-password" element={<ResetPassword />}></Route>
          {/* <Route path="reset-password" element={<ResetPassword />}></Route> */}
          <Route path="registration/:code" element={<Registration />}></Route>
          <Route path="account-types" element={<AccountTypesPage />}></Route>
          <Route path="add-details" element={<AddDetails />}></Route>
          <Route path="add-avatar" element={<AddAvatar />}></Route>
          <Route path="select-hobbies" element={<SelectHobbyList />}></Route>
          <Route path="verify-user" element={<VerifyUser />}></Route>
          <Route path="validation" element={<UserValidationPage />}></Route>
          <Route path="validation-success" element={<SuccessValidationPage />}></Route>
          <Route path="home" element={<Home />}></Route>
          {/* <Route path="home-business" element={<HomeBusiness />}></Route> */}
          <Route path="ads-dashboard" element={<AdvertiserDashboard />}></Route>
          <Route path="create-ad" element={<CreateAdPage />}></Route>
          <Route path="user-profile/:userId" element={<UserUnAuthProfile />}></Route>

          {/*admin routes  */}
          <Route path="admin">
            <Route path="AddIcons" element={<UploadHobbieIcons />}></Route>
            <Route path="dashboard" element={<Dashboard />}></Route>
            <Route path="addHobby" element={<AddHobbies />}></Route>
          </Route>

          {/*Account routes  */}
          <Route path="main">
            <Route path="profile" element={<ProfileSettings />}></Route>

            <Route path="account">
              <Route index element={<Home />} />
              <Route path="hobby/:hobyId" element={<HomeHobbyPage />} />
              <Route path="business" element={<HomeBusiness />} />
              <Route path="creator" element={<HomeCreatorePage />}></Route>
            </Route>
          </Route>
          <Route path="creator">
            <Route path="referral" element={<ReferralDashboard />}></Route>
          </Route>

          <Route path="onboarding">
            <Route path="redirect-to-stripe" element={<RedirectToStripe/>} />
            <Route path="success" element={<OnboardingSuccess />} />
            <Route path="refresh" element={<OnboardingRefresh />} />
          </Route>

          <Route path="privacy-policies" element={<UserPolicy />}></Route>
          <Route path="delete-account" element={<UserDeletion />}></Route>
          <Route path="user-support" element={<UserSupport />}></Route>
        </Routes>
      </UserProvider>

    </div >
  );
}

export default App;
