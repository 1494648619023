import React, { useContext, useEffect, useState } from "react";
import "./NavigationHeader.css";
import { Search, Plus, Bell, User, Share2, LogOut, LayoutDashboard, Home } from "lucide-react"; // Lucide icons
import { Avatar } from "antd";
import { UserContext } from "../../../context";
import { useNavigate } from "react-router-dom";
import UserService from "../../../services/UserService";
import PostCreator from "../../popups/PostCreator";
import { DashboardOutlined, DashOutlined } from "@ant-design/icons";
import RolesService from "../../../services/RoleService";
import logImg from "../../../assets/logo_home.png";

const NavigationHeader = ({ showAddPostBtn = true }) => {
    const navigate = useNavigate();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [user, setUser] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [roles, setRoles] = useState([])


    useEffect(() => {
        const user = UserService.getUserData();
        setUser(user)

        const roles = RolesService.getRolesData();
        setRoles(roles)
    }, [])

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleProfileClick = () => {
        navigate("/main/profile")
    };

    const handleReferralClick = () => {
        navigate("/creator/referral")
    };

    const handleLogoutClick = () => {
        localStorage.clear();
        navigate("/");
    };

    const handleDashboardClick = () => {
        navigate("/admin/dashboard")
    }

    const handleLogoClick = () => {
        navigate("/main/account")
    }

    return (
        <nav className="navigation-header">
            <PostCreator
                visible={isModalVisible}
                onClose={() => setIsModalVisible(false)}
            />
            <div className="nav-container">
                <div className="nav-left">
                    <div className="navigation-logo" onClick={handleLogoClick}>
                        <img src={logImg} alt="logo" className="navigation-logo-img" width={40} /> 
                        {/* <span className="navigation-logo-text">B</span> */}
                    </div>
                    <div className="nav-search">
                        <input type="text" placeholder="Search hobbyist" className="search-input" />
                        <Search className="search-icon" />
                    </div>
                </div>
                <div className="nav-right">
                    {showAddPostBtn && <button className="nav-button" onClick={() => setIsModalVisible(true)}>
                        <Plus className="icon" />
                    </button>}
                    <button className="nav-button">
                        <Bell className="icon" />
                    </button>
                    <div className="nav-profile-pic" onClick={toggleDropdown}>
                        <Avatar size={30} src={user?.image_url} />
                    </div>
                    {isDropdownOpen && (
                        <div className="dropdown-menu">
                            <div className="dropdown-item" onClick={handleLogoClick}>
                                <Home size={16} className="dropdown-icon" />
                                <span>Home</span>
                            </div>
                            <div className="dropdown-item" onClick={handleProfileClick}>
                                <User size={16} className="dropdown-icon" />
                                <span>Profile</span>
                            </div>
                            {roles.includes("admin") &&
                                <div className="dropdown-item" onClick={handleDashboardClick}>
                                    <DashboardOutlined size={16} className="dropdown-icon" />
                                    <span>Dashboard</span>
                                </div>
                            }
                            <div className="dropdown-item" onClick={handleReferralClick}>
                                <Share2 size={16} className="dropdown-icon" />
                                <span>Referral</span>
                            </div>
                            {user?.user_type !== "admin" && <div className="dropdown-item" onClick={handleLogoutClick}>
                                <LogOut size={16} className="dropdown-icon" />
                                <span>Logout</span>
                            </div>}

                        </div>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default NavigationHeader;