import React, { useContext, useState } from "react";
import "./Login.css";
import Logo from "../../../assets/logo_home.png";
// import axios from "axios";
// import { BASE_URL } from "../../global/api";
// import PageLoader from "../../components/common/PageLoader";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL, BASE_URL_DEV } from "../../../api/core";
import { Button, message } from "antd";
import QrCodeImg from "../../../assets/imgs/qrcode.jpeg";
import PlaystoreLogo from "../../../assets/imgs/playstore_logo.png";
import AppstoreLogo from "../../../assets/imgs/appstore_logo.png";

import { Link } from "react-router-dom";
import { UserContext } from "../../../context";
import UserService from "../../../services/UserService";
import HobbyService from "../../../services/HobbyService";
import RolesService from "../../../services/RoleService";

const Login = () => {
  const navigate = useNavigate();
  const { setUserTypeDetalis } = useContext(UserContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [responseErrrors, setResponseErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [showPopup, setShowPopup] = useState(false);

  const errorMsg = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
    });
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    console.log(!email)
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      errorMsg("Email is required");
      return;
    } else if (!regex.test(email)) {
      errorMsg("Invalid email address");
      return;
    }

    if (!password) {
      errorMsg("Password is required");
      return;
    } else if (password.length < 8) {
      errorMsg("Password must be at least 8 characters long");
      return;
    } else {
      setPasswordError("");
    }

    const hideLoading = message.loading("Login in, please wait..", 0);

    try {
      const response = await axios.post(`${BASE_URL}/login`, {
        email: email,
        password: password,
      });

      hideLoading();

      const { token, user, permissions, roles, business_profile } =
        response.data;


      UserService.setUserData(user)
      HobbyService.setHobbyData(user.hobbies)
      RolesService.setRolesData(roles)

      localStorage.setItem("token", token);

      if (user?.user_type === "business") {

        localStorage.setItem("business_profile", JSON.stringify(business_profile));
        navigate("/mian/account/business");
        return;
      }


      navigate("/main/account");

      // Check if the user is an admin
      // if (roles.includes("admin")) {

      // }
    } catch (error) {
      if (error.response) {
        console.log(error.response.status)
        if (error.response.status === 401) {
          message.error("Wrong email or passsword. please try again.");
          localStorage.removeItem("token"); // Remove expired token
        } else {
          message.error(error.response.data.message || "Something went wrong.");
        }
      } else {
        message.error("Network error. Please try again.");
      }
    } finally {
      hideLoading();
    }
  };

  const openAppStoreLink = (e) => {
    e.preventDefault(); // Prevent the default Link behavior
    window.open(
      "https://apps.apple.com/us/app/hobbee/id6532579423",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const openPlaystoreLink = (e) => {
    e.preventDefault(); // Prevent the default Link behavior
    window.open(
      "https://play.google.com/store/apps/details?id=com.virtualbeehive.hobbie&hl=en",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div style={styles.container}>
      {contextHolder}
      {/* Left Layout */}
      {/* {showPopup && <SelectAccountDialog onClose={() => setShowPopup(false)} />} */}
      <div style={styles.leftSection}>
        <h2 style={styles.headerText}>Get Hobbee app</h2>
        <img src={QrCodeImg} style={styles.qrCode} />
        <div style={styles.storeLinks}>
          <Link to="#" onClick={openPlaystoreLink}>
            <img src={PlaystoreLogo} width={140} className="playstore-logo" />
          </Link>
          <Link to="#" onClick={openAppStoreLink}>
            <img src={AppstoreLogo} width={140} className="appstore-logo" />
          </Link>
        </div>
      </div>

      {/* Right Layout */}
      <div style={styles.rightSection}>
        <div style={styles.logoContainer}>
          <img src={Logo} alt="Hobbee Logo" style={styles.logo} />

          <h1>Hobbee.fun</h1>
          <p>Pursue Your Passion</p>
        </div>

        <form onSubmit={handleLogin} style={styles.form}>
          <p style={{ ...styles.errorText, ...styles.centerError }}>
            {responseErrrors}
          </p>
          <div style={styles.inputGroup}>
            <input
              placeholder="Email or Username"
              type="email"
              id="email"
              style={styles.input}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailError && <p style={styles.errorText}>{emailError}</p>}
          </div>
          <div style={styles.inputGroup}>
            <input
              placeholder="password"
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={styles.input}
            />
            {passwordError && <p style={styles.errorText}>{passwordError}</p>}
          </div>

          <a
            href="/forgot-password"
            style={styles.forgotPassword}
            onClick={(e) => {
              e.preventDefault();
              navigate("/send-email");
            }}
          >
            Forgot Password?
          </a>
          <button type="submit" style={styles.signInButton}>
            Sign In
          </button>
        </form>
        <p>
          Don’t have an account?{" "}
          <a
            href="/register"
            style={styles.registerLink}
            onClick={(e) => {
              e.preventDefault();

              //set user type to normal
              setUserTypeDetalis({
                userType: "normal",
                businessName: "",
                category: "",
                description: "",
              });

              localStorage.setItem("user_type", "normal")
              navigate("registration/new");
            }}
          >
            Register Now!
          </a>
        </p>
        <p
          style={styles.footerText}
          onClick={(e) => {
            e.preventDefault();
            navigate("/account-types")
          }}
        >
          Create a page for Celebrity, Brand, or Business
        </p>
        <p style={styles.footerText}>Virtual Beehive Inc. 2024</p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    fontFamily: "Arial, sans-serif",
  },
  leftSection: {
    flex: 1,
    backgroundColor: "#FFD700", // Yellow background
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  headerText: {
    fontSize: "24px",
    marginBottom: "20px",
  },
  qrCode: {
    width: "200px",
    height: "200px",
    marginBottom: "20px",
  },
  storeLinks: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
  },
  storeBadge: {
    width: "120px",
    cursor: "pointer",
  },
  rightSection: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  logoContainer: {
    textAlign: "center",
    marginBottom: "20px",
  },
  logo: {
    width: "80px",
    marginBottom: "10px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    maxWidth: "400px",
  },
  input: {
    width: "100%",
    marginBottom: "15px",
    padding: "10px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  inputGroup: {
    positition: "rlative",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "stretch",
    with: "100%",
    minHeight: "50px",
    border: "none",
  },

  forgotPassword: {
    fontSize: "14px",
    color: "#b6a005",
    textDecoration: "none",
    marginBottom: "20px",
  },
  signInButton: {
    padding: "10px",
    fontSize: "16px",
    backgroundColor: "#FFD700",
    color: "black",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  errorText: {
    fontSize: "12px",
    color: "red",
    marginTop: "18px",
    height: 0,
  },
  centerEerror: {
    width: "100%",
    textAlign: "center",
  },
  registerLink: {
    color: "#b6a005",
    textDecoration: "none",
  },
  footerText: {
    cursor: "pointer",
    fontSize: "12px",
    color: "#888888",
    marginTop: "10px",
  },
};

export default Login;
