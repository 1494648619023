import React, { useState, useEffect, useCallback, useContext } from "react";
import axios from "axios";
import { message, Spin } from "antd";
import { BASE_URL, BASE_URL_DEV } from "../../../api/core";
import Post from "../../layouts/Post/Post";
import { UserContext } from "../../../context";
import UserService from "../../../services/UserService";
import CenteredLoader from "../Loader/CenteredLoader";

const InfiniteScroll = ({ setFeedLength, setShowHeader }) => {
  const { newUserPost } = useContext(UserContext);

  const [feeds, setFeeds] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [user, setUser] = useState(null)
  const [token, setToken] = useState("");

  // this will be set when a new feed is successfully sent to the database/server
  const [isNewFeedSaved, setIsNewFeedSaved] = useState(false)

  useEffect(() => {
    const loggedUser = UserService.getUserData()
    setUser(loggedUser)
  }, [])

  useEffect(() => {
    // Ensure newUserPost is valid before updating state
    // if (newUserPost && Object.keys(newUserPost).length > 0) {
    //   setFeeds((prevFeeds) => [newUserPost, ...prevFeeds]);
    // }

    setIsNewFeedSaved(true)

    console.log(newUserPost)
  }, [newUserPost?.isSaved]);


  useEffect(() => {
    fetchFeeds();

    // console.log(isNewFeedSaved.isSaved)

    //reset new feed is saved to false
    setIsNewFeedSaved(false)
  }, [newUserPost]);

  const fetchFeeds = async () => {

    setLoading(true);

    try {
      const token = localStorage.getItem("token");
      if (!token) return;

      setToken(token)

      const response = await axios.get(`${BASE_URL}/feeds`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { page },
      });


      const { feeds: newFeeds, hasMore: moreFeedsAvailable } = response.data;


      if (newUserPost) {

        setFeeds((prevFeeds) => {
          return [newUserPost, ...prevFeeds];
        })
        // [newUserPost,...prevFeeds];
      }

      setFeeds((prevFeeds) => {
        // Filter out items from newFeeds that already exist in prevFeeds
        const uniqueNewFeeds = newFeeds.filter(
          (newFeed) => !prevFeeds.some((prevFeed) => prevFeed?.id === newFeed?.id)
        );

        // Return a new array combining prevFeeds and the unique items from newFeeds
        return [...prevFeeds, ...uniqueNewFeeds];
      });

      setHasMore(moreFeedsAvailable);
      setPage((prevPage) => prevPage + 1);
      setFeedLength(feeds?.length);
    } catch (error) {
      console.log(error);
      message.error("Error fetching data: No Connection");
    } finally {
      // hideLoading();
      setLoading(false);
    }
  };

  const handleScroll = useCallback(() => {
    if (loading || !hasMore) return;

    if (
      window.innerHeight + window.scrollY >=
      document.documentElement.scrollHeight
    ) {
      fetchFeeds();
    }
  }, [loading, hasMore]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);


  // Function to add a like to a feed
  const handleLikePress = (feedId) => {
    const updatedFeeds = feeds.map((feed) => {
      if (feed.id === feedId) {
        // Check if current user has liked this post
        const likedIndex = feed?.likes.findIndex(
          (like) => like.user_id === user.id
        );

        // Toggle like
        if (likedIndex === -1) {
          feed.likes.push({ id: Date.now(), user_id: user.id });
          feed.likes_count++;

          saveLike(feedId);
        } else {
          feed.likes.splice(likedIndex, 1);
          feed.likes_count--;

          // deleteLike(feedId);

        }
      }
      return feed;
    });


    // console.log(updatedFeeds)
    // setFeeds(updatedFeeds);
  };

  const saveLike = async (feedId) => {
    const data = {
      post_id: feedId,
      user_id: user.id,
    };

    try {
      await axios.post(`${BASE_URL}/likes`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // setIsFeedRefresh(true);
    } catch (error) {
      console.error("Error toggling like:", error);
      // Handle error and revert UI changes if needed
    }
  };

  const handleAddComment = (postId, newComment) => {
    setFeeds((prevFeeds) =>
      prevFeeds.map((feed) =>
        feed.id === postId
          ? {
            ...feed,
            comments: [...feed.comments, newComment],
          }
          : feed
      )
    );
  }
  return (
    <div>
      {loading && <CenteredLoader loadMessage={"Loading more feeds..."} />
      }
      <ul>
        {feeds?.map((feed, index) => {
          return (
            <li key={index}>
              <Post
                feed={feed}
                handleAddComment={handleAddComment}
                handleLikePress={handleLikePress}
                setShowHeader={setShowHeader} />
            </li>
          );
        })}
      </ul>
      {loading && <p>Loading...</p>}
      {!hasMore && <p>No more feeds to load</p>}
    </div>
  );
};

export default InfiniteScroll;
