import { useState } from "react";
import { Copy, Pencil, Check } from "lucide-react";
import "./ReferalCode.css"

const ReferralCode = ({ mockProfileData }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div className="referral-container">
      {/* Show this when NOT in edit mode */}
      {!isEditing ? (
        <div className="referal-code-wrapper">
          <p className="referal-code">{mockProfileData.bio}</p>
          <div className="actions">
            <button className="copy-button">
              <Copy className="copy-icon" />
              <p>Copy</p>
            </button>

            <button className="edit-button" onClick={toggleEdit}>
              <Pencil className="edit-icon" />
              <p className="edit-text">Generate new code</p>
            </button>
          </div>
        </div>
      ) : (
        /* Show this when in edit mode */
        <div className="edit-mode">
          <div className="input-container">
            <div className="input-group">
              <label>Start Date</label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>

            <div className="input-group">
              <label>End Date</label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>

          <button className="save-button" onClick={toggleEdit}>
            <Check className="save-icon" />
            <p>Save</p>
          </button>
        </div>
      )}
    </div>
  );
};

export default ReferralCode;
