import React, { useState } from 'react';
import './AdvertiserDashboard.css';
import { useNavigate } from 'react-router-dom';

const AdvertiserDashboard = () => {
  const navigate = useNavigate();  
  
  const [ads, setAds] = useState([
    {
      id: 1,
      name: "Summer Sale Campaign",
      status: "Active",
      budget: 500,
      reach: 10000,
      clicks: 450
    },
    {
      id: 2,
      name: "New Product Launch",
      status: "Paused",
      budget: 1000,
      reach: 25000,
      clicks: 1200
    }
  ]);

  return (
    <div className="dashboard-container">
      <div className="dashboard-content">
        {/* Header */}
        <div className="dashboard-header">
          <h1 className="dashboard-title">Ad Manager</h1>
          <button className="back-button" onClick={() => window.history.back()}>
          ⬅ Back to Dashboard
        </button>
          <button className="create-ad-button" onClick={() => navigate("/create-ad")}>
            ➕ Create New Ad
          </button>
        </div>

        {/* Stats Overview */}
        <div className="stats-grid">
          <div className="stats-card">
            <span className="stats-icon">💰</span>
            <div>
              <p className="stats-label">Total Spend</p>
              <p className="stats-value">$1,500</p>
            </div>
          </div>
          
          <div className="stats-card">
            <span className="stats-icon">👥</span>
            <div>
              <p className="stats-label">Total Reach</p>
              <p className="stats-value">35,000</p>
            </div>
          </div>
          
          <div className="stats-card">
            <span className="stats-icon">📊</span>
            <div>
              <p className="stats-label">Total Clicks</p>
              <p className="stats-value">1,650</p>
            </div>
          </div>
        </div>

        {/* Active Ads Table */}
        <div className="table-container">
          <h2 className="table-title">Active Campaigns</h2>
          <table className="ads-table">
            <thead>
              <tr>
                <th>Campaign Name</th>
                <th>Status</th>
                <th>Budget</th>
                <th>Reach</th>
                <th>Clicks</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {ads.map((ad) => (
                <tr key={ad.id}>
                  <td>{ad.name}</td>
                  <td>
                    <span className={`status-badge ${ad.status.toLowerCase()}`}>
                      {ad.status}
                    </span>
                  </td>
                  <td>${ad.budget}</td>
                  <td>{ad.reach.toLocaleString()}</td>
                  <td>{ad.clicks.toLocaleString()}</td>
                  <td>
                    <button className="delete-button">🗑️</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdvertiserDashboard;
