import { createContext, useEffect, useState } from "react";

export const UserContext = createContext();
export const LayoutPanelContext = createContext();


export const UserProvider = ({ children }) => {
  // Define your initial state
  const [userGlobal, setUserGlobal] = useState(null);
  const [userHobbies, setUserHobbies] = useState([]);
  const [newUserPost, setNewUserPost] = useState(null);
  const [resetCode, setResetCode] = useState(null);
  const [userTypeDetalis, setUserTypeDetalis] = useState(null);

  const [email, setEmail] = useState("");

  useEffect(() => {
    const user = localStorage.getItem("user");
    setUserGlobal(JSON.parse(user));
  }, []);

  return (
    <UserContext.Provider
      value={{
        userGlobal,
        setUserGlobal,
        userHobbies,
        setUserHobbies,
        newUserPost,
        setNewUserPost,
        email,
        setEmail,
        resetCode,
        setResetCode,
        userTypeDetalis,  
        setUserTypeDetalis
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const LayoutPanelProvider = ({ children }) => {
  const [isPanelVisible, setIsPanelVisible] = useState(false);

  return (
    <LayoutPanelContext.Provider value={{ isPanelVisible, setIsPanelVisible }}>
      {children}
    </LayoutPanelContext.Provider>
  );
};