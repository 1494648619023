import "./ProfileTab.css";

const ProfileTab = () => {
  return (
    <div className="user-profile-container">
      <div className="card">
        <h3 className="card-title">About</h3>
        <p className="card-text">
          Tech content creator specializing in creative coding and development tutorials
        </p>
        <div className="expertise-section">
          <h4 className="expertise-title">Expertise</h4>
          <div className="expertise-tags">
            {["Creative Coding", "Web Development", "UI/UX", "Tech Tutorials"].map((tag) => (
              <span key={tag} className="tag">
                {tag}
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className="card">
        <h3 className="card-title">Contact & Links</h3>
        <div className="contact-info">
          <p className="card-text">Website: techcreative.dev</p>
          <p className="card-text">Business: contact@techcreative.dev</p>
        </div>
      </div>
    </div>
  );
};

export default ProfileTab;
