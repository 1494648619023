import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import Logo from "../../../assets/logo_home.png"; // Replace with the correct path
import { BASE_URL } from "../../../api/core";
import "./UserValidationPage.css"; // Ensure CSS is properly defined
import { UserContext } from "../../../context";
import UserService from "../../../services/UserService";
import ValidationEmailSentMessage from "../../../components/common/validation/ValidationEmailSentMessage";

const UserValidationPage = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { userTypeDetalis } = useContext(UserContext);
    const [messageApi, contextHolder] = message.useMessage();

    const [verificationCode, setVerificationCode] = useState("")
    const [showResendButton, setShowResendButton] = useState(true);
    const [resendTimer, setResendTimer] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [user, setUser] = useState(null)
    const [isEmailSent, setIsEmailSent] = useState(false)

    const errorMsg = (msg) => {
        messageApi.open({
            type: "error",
            content: msg,
        });
    };

    const successMsg = (msg) => {
        messageApi.open({
            type: "success",
            content: msg,
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        // For verification code, only allow numbers and limit to 6 characters
        if (name === 'verification_code' && !/^\d*$/.test(value)) {
            return;
        }

        setVerificationCode(value)
    };

    const validate = () => {
        let errors = {};
        if (!verificationCode) {
            errors.verification_code = "Verification code is required";
        } else if (verificationCode.length !== 6) {
            errors.verification_code = "Verification code must be 6 digits";
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    

    return (
        <div style={styles.container}>
            {contextHolder}
            {/* Left Section */}
            <div style={styles.leftSection}>
                <img src={Logo} alt="Hobbee Logo" style={styles.logo} />
                <h1 style={styles.title}>Hobbee</h1>
                <p style={styles.subtitle}>Pursue Your Passion</p>
            </div>

            {/* Right Section */}
            <div style={styles.rightSection}>
           <ValidationEmailSentMessage />
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent:"center",
  
        backgroundColor: "#FFD700", 
       
    },
    leftSection: {
        flex: 1,
        backgroundColor: "#FFD700", 
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        // padding: "20px",
        // height:"100%"
    },
    logo: {
        width: "80px",
        marginBottom: "10px",
    },
    title: {
        fontSize: "28px",
        fontWeight: "bold",
        marginBottom: "10px",
    },
    subtitle: {
        fontSize: "16px",
        textAlign: "center",
    },
    rightSection: {
        flex: 1,
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",

    },
    form: {
        width: "80%",
        maxWidth: "400px",
        display: "flex",
        flexDirection: "column",
    },
    formTitle: {
        fontSize: "24px",
        fontWeight: "bold",
        marginBottom: "10px",
        textAlign: "center",
    },
    formDescription: {
        fontSize: "14px",
        color: "#666",
        textAlign: "center",
        marginBottom: "20px",
    },
    inputGroup: {
        marginBottom: "20px",
        textAlign: "left"
    },
    input: {
        width: "100%",
        padding: "10px",
        fontSize: "16px",
        border: "1px solid #ccc",
        borderRadius: "5px",
    },
    button: {
        padding: "10px",
        fontSize: "16px",
        backgroundColor: "#FFD700",
        color: "#000000",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        transition: "opacity 0.3s",
        width: "100%",
        marginBottom: "10px",
    },
    resendButton: {
        padding: "10px",
        fontSize: "16px",
        backgroundColor: "#FFFFFF",
        color: "#b6a005",
        border: "1px solid #b6a005",
        borderRadius: "5px",
        cursor: "pointer",
        transition: "opacity 0.3s",
        width: "100%",
    },
    errorText: {
        fontSize: "12px",
        color: "red",
        marginTop: "5px",
    },
    centerError: {
        width: "100%",
        textAlign: "center",
    },
    timerText: {
        fontSize: "14px",
        color: "#666",
        textAlign: "center",
        margin: "10px 0",
    },
    switchText: {
        fontSize: "14px",
        marginTop: "20px",
        textAlign: "center",
    },
    link: {
        color: "#b6a005",
        textDecoration: "none",
    },
    actionsContainer: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "20px",
    },
};

export default UserValidationPage;