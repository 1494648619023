import React, { useEffect, useState } from 'react';
import { User, Settings as SettingsIcon, MessageCircle, Video, Palette, Bell, X, HelpCircle, Info, LogOut, Trash2 } from 'lucide-react';
import './ProfileSettings.css';
import NavigationHeader from '../../../components/common/NavigationHeader/NavigationHeader';
import UserService from '../../../services/UserService';

const tabs = [
  { name: 'Personal Information', key: 'personal', icon: <User size={20} /> },
  { name: 'Terms of Use', key: 'terms', icon: <SettingsIcon size={20} /> },
  { name: 'Contact Us', key: 'contact', icon: <MessageCircle size={20} /> },
  { name: 'Help Center', key: 'help', icon: <HelpCircle size={20} /> },
  { name: 'About Us', key: 'about', icon: <Info size={20} /> },
  { name: 'Logout', key: 'logout', icon: <LogOut size={20} /> },
  { name: 'Delete Account', key: 'delete', icon: <Trash2 size={20} /> }
];

const ProfileSettings = () => {
  const [activeTab, setActiveTab] = useState('personal');
  const [user, setUser] = useState(null)
  useEffect(() => {
    const user = UserService.getUserData()
    setUser(user)
  }, [])

  const renderContent = () => {
    switch (activeTab) {
      case 'personal':
        return (
          <>
            <section className="profile-section">
              <h2>Profile picture</h2>
              <div className="profile-picture-container">
                <img src={user?.image_url} alt="Profile" className="profile-picture" />
                <div className="profile-actions">
                  <button className="change-picture-button">Change picture</button>
                  <button className="delete-picture-button">Delete picture</button>
                </div>
              </div>
            </section>
            <section className="profile-section">
              <h2>Profile name</h2>
              <input type="text" className="profile-input" defaultValue={`${user?.firstname} ${user?.lastname}`} />
            </section>
            <section className="profile-section">
              <h2>Username</h2>
              <div className="username-container">
                <span className="username-symbol">@</span>
                <input type="text" className="profile-input" defaultValue={`${user?.username ? user?.username : "hobby"}`} />
              </div>
              {/* <p className="helper-text">Available change in 25/04/2024</p> */}
            </section>
            <section className="profile-section">
              {/* <h2>Status recently</h2> */}
              {/* <input type="text" className="profile-input" defaultValue="On duty" /> */}
            </section>
            <section className="profile-section">
              <h2>About me</h2>
              <textarea className="profile-textarea" defaultValue={user?.bio}/>
            </section>
          </>
        );
      case 'terms':
        return <div>Terms of Use Content</div>;
      case 'contact':
        return <div>Contact Us Content</div>;
      case 'help':
        return <div>Help Center Content</div>;
      case 'about':
        return <div>About Us Content</div>;
      case 'logout':
        return <div>Logout Content</div>;
      case 'delete':
        return <div>Delete Account Content</div>;
      default:
        return <div>Personal Information Content</div>;
    }
  };

  return (
    <div className="settings-container">
      <NavigationHeader showAddPostBtn={false}/>
      <aside className="settings-sidebar">
        <h2 className="settings-title">Settings</h2>
        <nav className="settings-nav">
          {tabs.map(tab => (
            <button
              key={tab.key}
              className={`settings-nav-item ${activeTab === tab.key ? 'active' : ''}`}
              onClick={() => setActiveTab(tab.key)}
            >
              {tab.icon}
              <span>{tab.name}</span>
            </button>
          ))}
        </nav>
      </aside>

      <main className="settings-content">
        <header className="settings-header">
          <h1>{tabs.find(tab => tab.key === activeTab)?.name}</h1>
          <button className="close-button"><X size={20} /></button>
        </header>
        {renderContent()}
      </main>
    </div>
  );
};

export default ProfileSettings;
