import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../../api/core";
import { message } from "antd";
import {  useParams } from "react-router-dom";

const UserTable = () => {
  const { userId } = useParams();

  const [hobbies, setHobbies] = useState([]);
  const [selectedUser, setSelectedUser] = useState("7");
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const token = localStorage.getItem("token");

    if (!token) return;

    const hideLoading = message.loading("Loading data, please wait..", 0);

    try {
      const response = await axios.get(
        `${BASE_URL}/user-hobbies/${userId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const { hobbies, selected_user } = response.data;
      setHobbies(hobbies);
      setSelectedUser(selected_user)

      hideLoading();
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h1>{selectedUser?.firstname}{" "}{selectedUser?.lastname}</h1>

      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr style={{ backgroundColor: "#f4f4f4" }}>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>ID</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>
              Icon
            </th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Name</th>
            
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>
             Description
            </th>

          </tr>
        </thead>
        <tbody>
          {hobbies.length > 0 ? (
            hobbies.map((user, index) => (
              <tr key={user.id}>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {index + 1}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <img src={user.icon} alt="profile" style={{ width: "50px", height: "50px", borderRadius: "50%" }} />
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                   {user.name}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {user.description}
                </td>
                
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" style={{ textAlign: "center", padding: "8px" }}>
                No hobbies found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default UserTable;
