import React, { useState, useEffect } from 'react';
import '../Home.css';

import NavigationHeader from '../../../components/common/NavigationHeader/NavigationHeader';
import LeftSideBar from '../../../components/layouts/Sidebar/LeftSideBar/LeftSideBar';
import PostSection from '../../../components/PostSection/PostSection';
import { useLocation } from 'react-router-dom';

function HomeHobbyPage() {
    const location = useLocation();
    const selectedHobby = location.state?.data;

    const [activeLayout, setActiveLayout] = useState("posts");
    const [showHeader, setShowHeader] = useState(true);
    const [feedLength, setFeedLength] = useState(0);
    const [refreshFeeds, setRefreshFeeds] = useState(false);
    const [sidebarPosition, setSidebarPosition] = useState("relative");
    const [showSidebarProfile, setShowSidebarProfile] = useState(true);
    const [showScrollbar, setShowScrollbar] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;

            console.log(scrollTop);
            if (scrollTop <= 0) {
                setSidebarPosition("relative");
                setShowSidebarProfile(true);
                setShowScrollbar(false);
            } else if (scrollTop >= 240) {
                setShowScrollbar(true);
            } else {
                setShowScrollbar(false);
            }

            if (scrollTop >= 400) {
                setSidebarPosition("fixed");
                setShowSidebarProfile(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className="dashboard-container">
            {/* Top Navigation */}
            <div className="top-navigation">
                <NavigationHeader/>
            </div>

            <div className="dashboard-layouts">
                {/* Left Sidebar */}
                <div 
                    className={`left-sidebar ${showScrollbar ? "show-scrollbar" : ""}`} 
                    style={{ position: sidebarPosition }}
                >
                    <LeftSideBar setActiveLayout={setActiveLayout} fromPage={"hobby"} showProfile={showSidebarProfile} />
                </div>

                {/* Main Content */}
                <div className="home-main-content" style={{ marginLeft: sidebarPosition === "fixed" ? 290 : 0 }}>
                    <main className="feed">
                        <PostSection selectedHobby={selectedHobby} setFeedLength={setFeedLength} setShowHeader={setShowHeader}/>
                    </main>
                </div>
                <div style={{ width: 220 }}></div>
            </div>
        </div>
    );
}

export default HomeHobbyPage;
