import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import Logo from "../../../assets/logo_home.png"; 
import { BASE_URL } from "../../../api/core";
import "../Registration/Registration.css"; 
import { UserContext } from "../../../context";
import SelectAccountPage from "../SelectAccount/SelectAccountPage";

const AccountTypesPage = () => {
  const { userTypeDetalis } = useContext(UserContext);



  return (
    <div style={styles.container}>
 
      {/* Left Section */}
      <div style={styles.leftSection}>
        <img src={Logo} alt="Hobbee Logo" style={styles.logo} />
        <h1 style={styles.title}>Hobbee</h1>
        <p style={styles.subtitle}>Pursue Your Passion</p>
      </div>

      {/* Right Section */}
      <div style={styles.rightSection}>
        <SelectAccountPage />
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    fontFamily: "Arial, sans-serif",
  },
  leftSection: {
    flex: 1,
    backgroundColor: "#FFD700", // Yellow background
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  logo: {
    width: "80px",
    marginBottom: "10px",
  },
  title: {
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  subtitle: {
    fontSize: "16px",
    textAlign: "center",
  },
  rightSection: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  form: {
    width: "80%",
    maxWidth: "400px",
    display: "flex",
    flexDirection: "column",
  },
  formTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
    textAlign: "center",
  },
  inputGroup: {
    marginBottom: "30px",
    textAlign: "left"
  },
  input: {
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  button: {
    padding: "10px",
    fontSize: "16px",
    backgroundColor: "#FFD700",
    color: "#000000",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },

  errorText: {
    fontSize: "12px",
    color: "red",
    marginTop: "10px",
    height: 0,
  },
  centerEerror: {
    width: "100%",
    textAlign: "center",
  },
  termsText: {
    fontSize: "12px",
    marginTop: "10px",
    textAlign: "center",
  },
  link: {
    color: "#b6a005",
    textDecoration: "none",
  },
  switchText: {
    fontSize: "14px",
    marginTop: "20px",
    textAlign: "center",
  },
};

export default AccountTypesPage;
