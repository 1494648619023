import React, { useEffect, useState } from "react";
import HobbyItem from "./HobbyItem";
import axios from "axios";
import { BASE_URL } from "../../../api/core";
import { message } from "antd";
import CenteredLoader from "../../common/Loader/CenteredLoader";

const HobbyList = ({}) => {
  const [hobbies, setHobbies] = useState([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchHobbies = async () => {
      const token = localStorage.getItem("token");
      
      setLoading(true)

      try {
        const response = await axios.get(`${BASE_URL}/hobbies`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          useCache: true,
        });

        const { hobbies } = response.data;
        setHobbies(hobbies);
      } catch (error) {
        console.error("Error fetching hobbies:", error);
      } finally {
       setLoading(false)
      }
    };

    fetchHobbies();
  }, []);

  return (
    <div>
      {loading && <CenteredLoader loadMessage={"Loading hobbies..."} />}
      {hobbies?.map((group, index) => (
        <HobbyItem
          key={index}
          index={index}
          group={group}
          hobbies={hobbies}
          setHobbies={setHobbies}
        />
      ))}
    </div>
  );
};

export default HobbyList;
