import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const OnboardingSuccess = () => {
  useEffect(() => {
    // You can optionally call backend to check status
    // axios.get('/api/stripe/check-status')
  }, []);

  return (
    <div style={{ padding: '2rem', textAlign: 'center' }}>
      <h2>✅ Onboarding Complete!</h2>
      <p>Your account is now ready to receive payouts.</p>
      <Link to="/creator/referral">
        <button>Go to Dashboard</button>
      </Link>
    </div>
  );
};

export default OnboardingSuccess;
