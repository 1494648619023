import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const customIcon = <LoadingOutlined style={{ fontSize: 20, color: "#ffd700" }} spin />;

const CenteredLoader = ({loadMessage}) => {
    return (
        <div style={{
            position: "fixed",
            top: "6%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "white",
            padding: "13px 12px",
            borderRadius: "8px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            zIndex: 9999999,
            pointerEvents: "all"
        }}>
            <Spin size="small" />
            <span style={{ fontSize: "14px", fontWeight: "500", color: "#333" }}>{loadMessage}</span>
        </div>
    );
};

export default CenteredLoader;
