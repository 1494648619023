import React, { useContext, useEffect, useState } from "react";
// import Header from "../../../components/layouts/header/Header";

import InfiniteScroll from "../../../components/common/InfiniteScroll/InfiniteScroll";
import HobbyList from "../../../components/lists/HobbyList/HobbyList";
import { UserContext } from "../../../context";
import MainMapPage from "../../map/hobby/MainMapPage";
import "../Home.css";
import { useNavigate } from "react-router-dom";
import { BarChart2, Layout, Settings } from "lucide-react";
import PostSection from "../../../components/PostSection/PostSection";

function HomeBusiness() {
  const navigate = useNavigate();

  const { setUserGlobal } = useContext(UserContext);
  const [switchView, setSwitchView] = useState("FeedView");
  const [selectedHobby, setSelectedHobby] = useState(0);
  const [feedLength, setFeedLength] = useState(0);
  const [refreshFeeds, setRefreshFeeds] = useState(false);
  const [showHeader, setShowHeader] = useState(true);

  useEffect(() => {
    // if (!localStorage.getItem("token")) {
    //   navigate("/");
    // }
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUserGlobal(user);
  }, []);

  const [selectedItem, setSelectedItem] = useState("Ads Manager");

  const renderContent = () => {
    switch (selectedItem) {
      case "Ads Manager":
        return (
          <div>
            <h2>Ads Manager</h2>
            <p>Manage your advertisements here.</p>
          </div>
        );
      case "Planner":
        return (
          <div>
            <h2>Planner</h2>
            <p>Plan your marketing campaigns here.</p>
          </div>
        );
      case "Insights":
        return (
          <div>
            <h2>Insights</h2>
            <p>View analytics and insights.</p>
          </div>
        );
      default:
        return (
          <div>
            <h2>Welcome</h2>
            <p>Select an item to view its content.</p>
          </div>
        );
    }
  };

  return (
    <div>
      {/* {showHeader ? <Header /> : null} */}

      <div className="main-content">
        <div className="left-sidebar">
        
        </div>

        {/* <LeftSideBar
          setSwitchView={setSwitchView}
          setSelectedHobby={setSelectedHobby}
          feedLength={feedLength}
        /> */}
        <div className="posts">
          {switchView === "HobbyView" && (
            <PostSection
              selectedHobby={selectedHobby}
              setFeedLength={setFeedLength}
            />
          )}
          {switchView === "MapView" && <MainMapPage />}
          {switchView === "FeedView" && (
            <div>
              <InfiniteScroll
                setShowHeader={setShowHeader}
                setFeedLength={setFeedLength}
                refreshFeeds={refreshFeeds}
              />
            </div>
          )}
          {switchView === "Hobbylist" && <HobbyList />}
        </div>

        {/* Right Sidebar */}
        <aside className="right-sidebar">
          <div className="sidebar-container">
            <button
              onClick={() => navigate("/ads-dashboard")}
              className="sidebar-button"
            >
              <div className="flex items-center">
                <BarChart2 className="sidebar-icon" />
                Ads Manager
              </div>
            </button>
            <button className="sidebar-button">
              <div className="flex items-center">
                <Layout className="sidebar-icon" />
                Planner
              </div>
            </button>
            <button className="sidebar-button">
              <div className="flex items-center">
                <Settings className="sidebar-icon" />
                Settings
              </div>
            </button>
          </div>
        </aside>
      </div>
    </div>
  );
}

export default HomeBusiness;
