import React, { useState } from 'react';
import './CreateAdPage.css';

const CreateAdPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    objective: 'awareness',
    targetAudience: '',
    budget: '',
    duration: '7',
    image: null,
    description: ''
  });

  const [previewMode, setPreviewMode] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleImageChange = (e) => {
    if (e.target.files.length > 0) {
      setFormData(prev => ({
        ...prev,
        image: URL.createObjectURL(e.target.files[0])
      }));
    }
  };

  return (
    <div className="ad-container">
      <div className="ad-content">
        {/* Header */}
        <button className="back-button" onClick={() => window.history.back()}>
          ⬅ Back to Dashboard
        </button>

        <div className="ad-card">
          <h2 className="ad-title">Create New Ad Campaign</h2>

          <div className="ad-grid">
            {/* Form Section */}
            <div className={`form-section ${previewMode ? 'hidden' : ''}`}>
              <label>Campaign Name</label>
              <input
                type="text"
                name="name"
                placeholder="Enter campaign name"
                value={formData.name}
                onChange={handleInputChange}
              />

              <label>Objective</label>
              <select name="objective" value={formData.objective} onChange={handleInputChange}>
                <option value="awareness">Brand Awareness</option>
                <option value="traffic">Website Traffic</option>
                <option value="engagement">Engagement</option>
                <option value="conversions">Conversions</option>
              </select>

              <label>Target Audience</label>
              <input
                type="text"
                name="targetAudience"
                placeholder="E.g., Ages 18-35, interested in technology"
                value={formData.targetAudience}
                onChange={handleInputChange}
              />

              <label>Daily Budget ($)</label>
              <input
                type="number"
                name="budget"
                placeholder="Enter amount"
                value={formData.budget}
                onChange={handleInputChange}
              />

              <label>Duration (days)</label>
              <select name="duration" value={formData.duration} onChange={handleInputChange}>
                <option value="7">7 days</option>
                <option value="14">14 days</option>
                <option value="30">30 days</option>
                <option value="custom">Custom</option>
              </select>

              <label>Ad Image</label>
              <div className="upload-box">
                <input type="file" accept="image/*" onChange={handleImageChange} id="image-upload" />
                <label htmlFor="image-upload" className="upload-label">📷 Click to upload image</label>
              </div>

              <label>Ad Description</label>
              <textarea
                name="description"
                placeholder="Enter your ad description"
                rows="4"
                value={formData.description}
                onChange={handleInputChange}
              />
            </div>

            {/* Preview Section */}
            <div className={`preview-section ${!previewMode ? 'hidden' : ''}`}>
              <h3>Ad Preview</h3>
              {formData.image ? (
                <img src={formData.image} alt="Ad preview" className="ad-preview-image" />
              ) : (
                <div className="placeholder-box">📷 No Image Selected</div>
              )}
              <h4>{formData.name || 'Campaign Name'}</h4>
              <p>{formData.description || 'Ad description will appear here'}</p>

              <div className="alert-box">
                Estimated Reach: {formData.budget ? `${(Number(formData.budget) * 100).toLocaleString()} - ${(Number(formData.budget) * 300).toLocaleString()} people/day` : 'Add budget to see estimate'}
              </div>
            </div>
          </div>

          {/* Mobile Preview Toggle */}
          <button className="preview-toggle" onClick={() => setPreviewMode(!previewMode)}>
            👁 {previewMode ? 'Edit Ad' : 'Preview Ad'}
          </button>

          {/* Action Buttons */}
          <div className="action-buttons">
            <button className="draft-button">Save as Draft</button>
            <button className="launch-button">🚀 Launch Campaign</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAdPage;
