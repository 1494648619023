import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const RedirectToStripe = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const stripeUrl = query.get('url');

  useEffect(() => {
    if (stripeUrl) {
      setTimeout(() => {
        window.location.href = stripeUrl;
      }, 1500);
    } else {
      navigate('/'); // fallback
    }
  }, [stripeUrl, navigate]);

  return (
    <div style={{ padding: '2rem', textAlign: 'center' }}>
      <h2>🔄 Redirecting you to Stripe...</h2>
      <p>Please wait while we connect you to the secure onboarding page.</p>
    </div>
  );
};

export default RedirectToStripe;
