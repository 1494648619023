const HobbyService = (() => {
    // Function to get user data from localStorage
    const getHobbyData = () => {
        const userData = localStorage.getItem("user_hobbies");//my hobbies
        return userData ? JSON.parse(userData) : null;
    };

    // Function to set user data into localStorage after login
    const setHobbyData = (user) => {
        localStorage.setItem("user_hobbies", JSON.stringify(user));
    };

    // Function to clear user data (for logout)
    const clearHobbyData = () => {
        localStorage.removeItem("user_hobbies");
    };

    return {
        getHobbyData,
        setHobbyData,
        clearHobbyData,
    };
})();

// Make it globally accessible
export default HobbyService;
