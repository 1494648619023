import "./ContentTab.css";

const ContentTab = () => {
  const posts = [
    {
      title: "Building Creative Interfaces with Three.js",
      views: "2.3k",
      type: "Tutorial",
    },
    {
      title: "Advanced CSS Animation Techniques",
      views: "1.8k",
      type: "Guide",
    },
    {
      title: "Responsive Design Best Practices",
      views: "3.1k",
      type: "Tips",
    },
  ];

  return (
    <div className="latest-posts-container">
      <div className="card">
        <h3 className="card-title">Latest Posts</h3>
        <div className="post-list">
          {posts.map((post) => (
            <div key={post.title} className="post-item">
              <div>
                <h4 className="post-title">{post.title}</h4>
                <span className="post-type">{post.type}</span>
              </div>
              <div className="post-views">{post.views} views</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContentTab;
