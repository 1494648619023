import React, { useState } from 'react';
import { Mail, RefreshCw, AlertCircle, ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import UserService from '../../../services/UserService';
import { BASE_URL } from '../../../api/core';
import axios from 'axios';

const ValidationEmailSentMessage = () => {
  const navigate = useNavigate()

  const [isResending, setIsResending] = useState(false);
  const [resendSuccess, setResendSuccess] = useState(false);
  const [resendError, setResendError] = useState(false);
  const [user, setUser] = useState(UserService.getUserData())
  const userEmail = "john.doe@example.com"; // This would come from your auth context or state

  const handleResendEmail = async () => {

    const token = localStorage.getItem("token")
    if (!token) {
      return;
    }

    setIsResending(true);
    setResendSuccess(false);
    setResendError(false);


    try {
      const response = await axios.post(`${BASE_URL}/email/verification-notification`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      );

      // if (response && response.data.success) {
      //   successMsg("Verification code sent successfully!");
      //   setResendTimer(60); // 60-second cooldown
      // }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.message || "Failed to resend verification code");
      } else {
        alert("Something went wrong. Please try again later.");
      }

    } finally {
      setIsResending(false);
      setResendSuccess(true);
    }
  };

  return (
    <div className="email-sent-container">
      <div className="email-sent-card">
        {/* Email Icon */}
        <div className="icon-container">
          <div className="email-icon-circle">
            <Mail className="mail-icon" />
          </div>
        </div>

        {/* Title and Message */}
        <h1 className="email-sent-title">Verification Email Sent</h1>
        <p className="email-sent-message">
          We've sent a verification link to <strong>{user.email}</strong>.
          Please check your inbox and click the link to complete your account verification.
        </p>

        {/* Instructions */}
        <div className="instructions-container">
          <h2 className="instructions-title">Next Steps:</h2>
          <ol className="instructions-list">
            <li>Check your inbox for an email from our team</li>
            <li>Click the verification link in the email</li>
            <li>If the link works, you'll be redirected to the verified page</li>
          </ol>
        </div>

        {/* Email not received notice */}
        <div className="email-notice">
          <AlertCircle className="notice-icon" />
          <p className="notice-text">
            Don't see the email? Check your spam folder or click the resend button below.
          </p>
        </div>

        {/* Status Messages */}
        {resendSuccess && (
          <div className="status-message success">
            Email successfully resent! Please check your inbox.
          </div>
        )}

        {resendError && (
          <div className="status-message error">
            Something went wrong. Please try again later.
          </div>
        )}

        {/* Buttons */}
        <div className="button-container">
          <button
            className="resend-button"
            onClick={handleResendEmail}
            disabled={isResending}
          >
            {isResending ? (
              <>
                <RefreshCw className="button-icon spinning" />
                Sending...
              </>
            ) : (
              <>
                <RefreshCw className="button-icon" />
                Resend Verification Email
              </>
            )}
          </button>

          <button className="back-button" onClick={() => navigate("/")}>
            <ArrowLeft className="button-icon" />
            Back to Login
          </button>
        </div>

        {/* Help Text */}
        {/* <p className="help-text">
          Still having trouble? <a href="#" className="help-link">Contact Support</a>
        </p> */}
      </div>

      <style jsx>{`
        .email-sent-container {
          display: flex;
          background-color: #f9fafb;
    
        }

        .email-sent-card {
          width: 100%;
          padding: 32px;
          background-color: white;
          border-radius: 8px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        .icon-container {
          display: flex;
          justify-content: center;
          margin-bottom: 24px;
        }

        .email-icon-circle {
          background-color: #f3edc2;
          padding: 16px;
          border-radius: 50%;
        }

        .mail-icon {
          height: 40px;
          width: 40px;
          color: #ffd700;
        }

        .email-sent-title {
          font-size: 24px;
          font-weight: 700;
          text-align: center;
          color: #1f2937;
          margin-bottom: 16px;
        }

        .email-sent-message {
          text-align: center;
          color: #4b5563;
          margin-bottom: 24px;
          line-height: 1.5;
        }

        .instructions-container {
          background-color: #f3f4f6;
          padding: 16px;
          border-radius: 8px;
          margin-bottom: 24px;
        }

        .instructions-title {
          font-size: 16px;
          font-weight: 600;
          color: #1f2937;
          margin-bottom: 12px;
        }

        .instructions-list {
          padding-left: 20px;
          margin: 0;
          color: #4b5563;
        }

        .instructions-list li {
          margin-bottom: 8px;
          line-height: 1.5;
        }

        .instructions-list li:last-child {
          margin-bottom: 0;
        }

        .email-notice {
          display: flex;
          align-items: flex-start;
          padding: 12px;
          background-color: #fff7ed;
          border: 1px solid #ffedd5;
          border-radius: 6px;
          margin-bottom: 24px;
        }

        .notice-icon {
          width: 20px;
          height: 20px;
          color: #f97316;
          margin-right: 12px;
          flex-shrink: 0;
        }

        .notice-text {
          color: #9a3412;
          font-size: 14px;
          margin: 0;
          line-height: 1.5;
        }

        .status-message {
          padding: 12px;
          border-radius: 6px;
          text-align: center;
          margin-bottom: 16px;
        }

        .status-message.success {
          background-color: #d1fae5;
          color: #065f46;
        }

        .status-message.error {
          background-color: #fee2e2;
          color: #b91c1c;
        }

        .button-container {
          display: flex;
          flex-direction: column;
          gap: 12px;
          margin-bottom: 20px;
        }

        .resend-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 12px 16px;
          background-color: #e4c000;
          color: black;
          font-weight: 500;
          border: none;
          border-radius: 6px;
          cursor: pointer;
          transition: background-color 0.2s;
        }

        .resend-button:hover:not(:disabled) {
          background-color:rgb(189, 161, 6);
        }

        .resend-button:disabled {
          background-color:rgb(255, 242, 171);
          cursor: not-allowed;
        }

        .back-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 12px 16px;
          background-color: transparent;
          color: #4b5563;
          font-weight: 500;
          border: 1px solid #d1d5db;
          border-radius: 6px;
          cursor: pointer;
          transition: background-color 0.2s;
        }

        .back-button:hover {
          background-color: #f3f4f6;
        }

        .button-icon {
          height: 16px;
          width: 16px;
          margin-right: 8px;
        }

        .spinning {
          animation: spin 1s linear infinite;
        }

        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        .help-text {
          text-align: center;
          font-size: 14px;
          color: #6b7280;
          margin: 0;
        }

        .help-link {
          color: #0ea5e9;
          text-decoration: none;
        }

        .help-link:hover {
          text-decoration: underline;
        }
      `}</style>
    </div>
  );
};

export default ValidationEmailSentMessage;