import React, { useState } from 'react';
import { Calendar, BarChart, User, FileText, Copy, Settings, Pencil } from 'lucide-react';
import './HomeCreatorPage.css';
import ProfileImg from '../../../assets/imgs/img9.jpg';
import ProfileTab from './tabs/Profile/ProfileTab';
import ContentTab from './tabs/Content/ContentTab';
import ScheduleTab from './tabs/Schedule/ScheduleTab';
import AnalyticsTab from './tabs/Analytics/AnalysticsTab';
import ReferralCode from './referal/ReferalCode';

const HomeCreatorPage = () => {
  const [activeTab, setActiveTab] = useState('profile');

  const mockProfileData = {
    username: 'creativecoder',
    displayName: 'Tech Creative',
    bio: 'Your referral code: #c45566',
    followers: 24500,
    following: 320,
    posts: 157,
    earnings: 2345.67,
  };

  const tabContent = {
    profile: { title: 'Profile', content: <div className="tab-content"><ProfileTab /></div> },
    content: { title: 'Content', content: <div className="tab-content"><ContentTab /></div> },
    schedule: { title: 'Schedule', content: <div className="tab-content"><ScheduleTab /></div> },
    analytics: { title: 'Analytics', content: <div className="tab-content"><AnalyticsTab /></div> },
  };

  return (
    <div className="creator-page-container">
      {/* Profile Header */}
      <div className="profile-header">
        <div className="profile-info">
          <div className="profile-details-wrapper">
            <img src={ProfileImg} alt="Profile" className="profile-picture" />
            <div className="profile-details">
              <h2>{mockProfileData.displayName}</h2>
              <p className="username">@{mockProfileData.username}</p>
            </div>
          </div>
          <ReferralCode mockProfileData={mockProfileData} />
          {/* <div className="referal-code-wrapper">
            <p className="referal-code">{mockProfileData.bio}</p>
            <div className="actions">
              <button className="copy-button">
                <Copy className="copy-icon" />
                <p>Copy</p>
              </button>

              <button>
                <button className="edit-button">
                  <Pencil className="edit-icon" />
                  <p className="edit-text">Edit</p>
                </button>
              </button>
            </div>
          </div> */}
        </div>
        {/* <div className="header-buttons">
          <button className="header-icon">
            <Settings size={20} />
          </button>
          <button className="header-icon">
            <User size={20} />
          </button>
        </div> */}
      </div>

      {/* Profile Stats */}
      <div className="profile-stats">
        <div className="stat-item">
          <h3>{mockProfileData.posts}</h3>
          <p>Posts</p>
        </div>
        <div className="stat-item">
          <h3>{mockProfileData.followers.toLocaleString()}</h3>
          <p>Followers</p>
        </div>
        <div className="stat-item">
          <h3>{mockProfileData.following}</h3>
          <p>Following</p>
        </div>
        <div className="stat-item">
          <h3>${mockProfileData.earnings.toFixed(2)}</h3>
          <p>Earnings</p>
        </div>
      </div>

      {/* Navigation Tabs */}
      <div className="nav-tabs">
        {Object.entries(tabContent).map(([key, { title }]) => (
          <button
            key={key}
            onClick={() => setActiveTab(key)}
            className={`tab-item ${activeTab === key ? 'active-tab' : ''}`}
          >
            {key === 'profile' && <User className="icon" />}
            {key === 'content' && <FileText className="icon" />}
            {key === 'schedule' && <Calendar className="icon" />}
            {key === 'analytics' && <BarChart className="icon" />}
            <span>{title}</span>
          </button>
        ))}
      </div>

      {/* Content Area */}
      <div className="content-area">{tabContent[activeTab].content}</div>
    </div>
  );
};

export default HomeCreatorPage;
