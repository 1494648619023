import React, { useState } from "react";
import "./Post.css";
import FeedHeaderCard from "../../feedcard/FeedHeaderCard";
import { AiFillLike } from "react-icons/ai";
import { FaRegComment } from "react-icons/fa6";
import Modal from "../../common/modal/Modal";
import CommentBox from "../CommentBox/CommentBox";
import { Search, Bell, Plus, Heart, ThumbsUp, MessageSquare } from 'lucide-react';
import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";

const Post = ({ feed, handleLikePress, setShowHeader, handleAddComment }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function convertToPlain(html) {
    // Create a new div element
    var tempDivElement = document.createElement("div");

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }

  const showComentBox = () => {
    setShowHeader(false);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setShowHeader(true);
  };

  return (
    <>
      <div>
        <Modal
          show={isModalOpen}
          onClose={closeModal}
          contentWidth={"100%"}
          contentHeight={"100vh"}
          showCloseBtn={false}
        >
          <CommentBox
            onClose={closeModal}
            post={feed}
            handleAddComment={handleAddComment}
          />
        </Modal>
      </div>
      <article key={feed.id} className="post-card">
        <div className="post-header">
          <div>
            <div className="post-avatar">
              <Avatar src={feed?.users?.image_url} size={40} />
            </div>
            <div className="post-meta">
              <h3>{feed?.users?.firstname}{" "}{feed?.users?.lastname}</h3>
              <p>{feed?.time_ago} | {feed?.hobbies?.name}</p>
            </div>
            </div>
          <p style={{ alignSelf: "flex-end" }}>{feed?.isSaved}</p>
        </div>

        <p className="post-content">{convertToPlain(feed?.content)}</p>
        <div className="post-media">
          {feed.media_type === "image" && <> {feed?.image_url && <img src={feed?.image_url} />}</>}
          {feed.media_type === "video" && <video width="100%" height={"400px"} className="video-player" controls>
            <source src={feed?.image_url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>}
        </div>

        <div className="post-actions">
          <button className="action-button" onClick={() => handleLikePress(feed?.id)}>
            <ThumbsUp />
            <span>{feed.likes_count} likes</span>
          </button>
          <button className="action-button" onClick={showComentBox}>
            <MessageSquare />
            <span>{feed.comments.length} comments</span>
          </button>
        </div>
      </article>
      {/* <main className="main-feed">
        <div className="post-card">
          <div className="post-header">
            <FeedHeaderCard feed={feed} />
          </div>
          <div className="post-content">
            <p className="post-content">{convertToPlain(feed?.content)}</p>
            <div className="post-media">
              {feed.media_type === "image" ? (
                <> {feed?.image_url && <img src={feed?.image_url} />}</>
              ) : (
                <video width="100%" height={"400px"} controls>
                  <source src={feed?.image_url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          </div>
          <div className="post-actions">
            <div className="activity-wrapper">
              <div
                className="activity-btns like"
                onClick={() => handleLikePress(feed?.id)}
              >
                <AiFillLike />
                <p>{feed?.likes_count} likes</p>
              </div>
              <div className="activity-btns like" onClick={showComentBox}>
                <FaRegComment />
                <p>{feed?.comments?.length} comments</p>
              </div>
            </div>
          </div>
        </div>
      </main> */}
    </>
  );
};

export default Post;
