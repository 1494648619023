import React, { useContext, useState } from "react";
import "./SelectAccountPage.css";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context";
import { Star, Briefcase, Users } from "lucide-react";

const SelectAccountPage = ({ onClose }) => {
  const navigate = useNavigate();

  const { setUserTypeDetalis } = useContext(UserContext);

  const [userType, setUserType] = useState(null);
  const [businessName, setBusinessName] = useState("");
  const [category, setCategory] = useState("Media");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({
    businessName: "",
    description: ""
  });

  const handleOptionSelect = (type) => {
    setUserType(type);

    navigate("/registration/new")
    //set type
    localStorage.setItem("user_type", type)
  };

  const handleGoBack = () => {
    setUserType(null);
    setBusinessName("");
    setDescription("");
    setErrors({
      businessName: "",
      description: ""
    });

    navigate("/")
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      businessName: "",
      description: ""
    };

    // Validate business name
    if (!businessName.trim()) {
      newErrors.businessName = "Name is required";
      valid = false;
    } else if (businessName.length < 2) {
      newErrors.businessName = "Name must be at least 2 characters";
      valid = false;
    }

    // Validate description
    if (!description.trim()) {
      newErrors.description = "Description is required";
      valid = false;
    } else if (description.length < 10) {
      newErrors.description = "Description must be at least 10 characters";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setUserTypeDetalis({ userType, businessName, description, category });
      navigate("/registration/new");
    }
  };

  return (

    <>
      <h2 className="title">Choose Your Account Type</h2>

      <button
        className="option"
        onClick={() => handleOptionSelect("celebrity")}
      >
        <div className="option-icon"><Star size={24} /></div>
        <div className="option-content">
          <div className="option-title">Celebrity</div>
          <div className="option-description">
            Create a public profile to connect with your fans
          </div>
        </div>
      </button>

      <button
        className="option"
        onClick={() => handleOptionSelect("influencer")}
      >
        <div className="option-icon"><Users size={24} /></div>
        <div className="option-content">
          <div className="option-title">Influencer</div>
          <div className="option-description">
            Build your personal brand and connect with your audience
          </div>
        </div>
      </button>

      <button
        className="option"
        onClick={() => handleOptionSelect("business")}
      >
        <div className="option-icon"><Briefcase size={24} /></div>
        <div className="option-content">
          <div className="option-title">Business</div>
          <div className="option-description">
            Establish your brand and grow your business
          </div>
        </div>
      </button>

      <div className="footer">
        You can always change your account type later in settings
      </div>
      <div className="button-group">
        <button className="back-button" onClick={handleGoBack}>
          ← Back
        </button>
       
      </div>
    </>

  );
};

export default SelectAccountPage;