import "./AnalyticsTab.css";

const AnalyticsTab = () => {
  const stats = [
    { label: "Avg. Views per Post", value: "2,450" },
    { label: "Engagement Rate", value: "8.2%" },
    { label: "Monthly Growth", value: "+12.5%" },
    { label: "Top Category", value: "Tutorials" },
  ];

  return (
    <div className="performance-container">
      <div className="card">
        <h3 className="card-title">Performance Overview</h3>
        <div className="performance-grid">
          {stats.map((stat) => (
            <div key={stat.label} className="performance-item">
              <div className="performance-label">{stat.label}</div>
              <div className="performance-value">{stat.value}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AnalyticsTab;
