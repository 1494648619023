import React, { useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../api/core';
import { useNavigate } from 'react-router-dom';

const OnboardingRefresh = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleRetry = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem("token");

            const headers = {
              Authorization: `Bearer ${token}`
            };

            const response = await axios.post(`${BASE_URL}/stripe/retry-onboarding`,
               {}, { headers });

            console.log(response.data.onboarding_url);
            window.location.href = response.data.onboarding_url;
        } catch (err) {
            alert('Error restarting onboarding.');
        } finally {
            setLoading(false);
        }
    };

    const handleBack = () => {
        navigate('/creator/referral'); // Change to your actual app route
    };

    return (
        <div style={{ padding: '2rem', textAlign: 'center' }}>
            <h2>😕 You didn’t finish onboarding</h2>
            <p>To start receiving money, you need to complete onboarding.</p>
            
            <div style={{ margin: '1rem 0' }}>
                <button onClick={handleRetry} disabled={loading}>
                    {loading ? 'Redirecting...' : 'Try Again'}
                </button>
            </div>

            <div>
                <button onClick={handleBack} style={{ background: '#ccc' }}>
                    Back to App
                </button>
            </div>
        </div>
    );
};

export default OnboardingRefresh;
