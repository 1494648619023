import React, { useEffect, useState } from 'react';
import { CheckCircle, ChevronRight, Mail } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const ValidationSuccessMessage = () => {
  const navigate = useNavigate();
  
  const [countdown, setCountdown] = useState(5);
  const username = "";
  
  useEffect(() => {
    // Auto-redirect countdown timer
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
    // Redirect logic would go here
    // history.push('/dashboard');
  }, [countdown]);

  return (
    <div className="success-page-container">
      <div className="success-card">
        {/* Success Icon */}
        <div className="icon-container">
          <div className="success-icon-circle">
            <CheckCircle className="success-icon" />
          </div>
        </div>
        
        {/* Success Message */}
        <h1 className="success-title">
          Validation Successful!
        </h1>
        <p className="success-message">
          Welcome, {username}! Your account has been successfully validated.
        </p>
        
        {/* Divider */}
        <div className="divider"></div>
        
        {/* Next Steps Section */}
        <div className="next-steps-section">
          <h2 className="section-title">
            Next Steps
          </h2>
          
          <ul className="steps-list">
            <li className="step-item">
              <ChevronRight className="step-icon" />
              <span className="step-text">Complete your profile information</span>
            </li>
            <li className="step-item">
              <ChevronRight className="step-icon" />
              <span className="step-text">Explore available features in your dashboard</span>
            </li>
            <li className="step-item">
              <ChevronRight className="step-icon" />
              <span className="step-text">Set up notification preferences</span>
            </li>
          </ul>
        </div>
        
        {/* Email Notification */}
        {/* <div className="email-notification">
          <Mail className="email-icon" />
          <p className="email-text">
            We've sent a confirmation to your email with additional information and resources.
          </p>
        </div>
         */}
        {/* Navigation Buttons */}
        <div className="button-container">
          <button 
            className="primary-button"
            onClick={() => navigate("/")}
          >
            Login to start your journey
          </button>
          
          {/* <p className="redirect-text">
            Redirecting automatically in {countdown} seconds...
          </p> */}
          
          <button 
            className="secondary-button"
            onClick={() => {/* Navigate to help */}}
          >
            Need Help?
          </button>
        </div>
      </div>

      <style jsx>{`
        .success-page-container {
          display: flex;
     
          min-height: 100vh;
          background-color: #f9fafb;
        }

        .success-card {
          width: 100%;
          padding: 32px;
          margin: 0 auto;
          background-color: white;
          border-radius: 8px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        .icon-container {
          display: flex;
          justify-content: center;
          margin-bottom: 24px;
        }

        .success-icon-circle {
          background-color: #d1fae5;
          padding: 12px;
          border-radius: 50%;
        }

        .success-icon {
          height: 48px;
          width: 48px;
          color: #10b981;
        }

        .success-title {
          font-size: 24px;
          font-weight: 700;
          text-align: center;
          color: #1f2937;
          margin-bottom: 8px;
        }

        .success-message {
          text-align: center;
          color: #4b5563;
          margin-bottom: 24px;
        }

        .divider {
          border-top: 1px solid #e5e7eb;
          margin: 24px 0;
        }

        .next-steps-section {
          margin-bottom: 24px;
        }

        .section-title {
          font-size: 18px;
          font-weight: 600;
          color: #1f2937;
          margin-bottom: 12px;
        }

        .steps-list {
          list-style-type: none;
          padding: 0;
          margin: 0;
        }

        .step-item {
          display: flex;
          align-items: flex-start;
          margin-bottom: 12px;
        }

        .step-icon {
          height: 20px;
          width: 20px;
          color: #10b981;
          margin-right: 8px;
          flex-shrink: 0;
        }

        .step-text {
          color: #4b5563;
        }

        .email-notification {
          background-color: #eff6ff;
          padding: 16px;
          border-radius: 6px;
          display: flex;
          align-items: flex-start;
          margin-bottom: 24px;
        }

        .email-icon {
          height: 20px;
          width: 20px;
          color: #3b82f6;
          margin-right: 12px;
          flex-shrink: 0;
        }

        .email-text {
          font-size: 14px;
          color: #1d4ed8;
          margin: 0;
        }

        .button-container {
          display: flex;
          flex-direction: column;
        }

        .primary-button {
          width: 100%;
          padding: 10px 16px;
          background-color: #ffd700;
          color: black;
          font-weight: 500;
          border: none;
          border-radius: 6px;
          cursor: pointer;
          transition: background-color 0.2s;
          margin-bottom: 12px;
        }

        .primary-button:hover {
          background-color:rgb(196, 168, 8);
        }

        .redirect-text {
          text-align: center;
          font-size: 14px;
          color: #6b7280;
          margin-bottom: 12px;
        }

        .secondary-button {
          width: 100%;
          padding: 10px 16px;
          background-color: transparent;
          color: #4b5563;
          font-weight: 500;
          border: 1px solid #d1d5db;
          border-radius: 6px;
          cursor: pointer;
          transition: background-color 0.2s;
        }

        .secondary-button:hover {
          background-color: #f3f4f6;
        }
      `}</style>
    </div>
  );
};

export default ValidationSuccessMessage;