import React, { useCallback, useEffect, useState } from "react";
import { GoogleMap, LoadScript, OverlayView } from "@react-google-maps/api";
import axios from "axios";
import { BASE_URL } from "../../../api/core";
import "./MapComponent.css";
import { Button, message } from "antd";
import UserService from "../../../services/UserService";
import CenteredLoader from "../Loader/CenteredLoader";

const containerStyle = {
  width: "100%",
  height: "400px",
};

function MapComponent({ flag, selectedHobby = null }) {
  const [messageApi, contextHolder] = message.useMessage();

  const [activeButton, setActiveButton] = useState("locations"); // Track active button
  const [activities, setActivities] = useState([]);
  const [user, setUser] = useState(null);
  const [center, setCenter] = useState(null);
  const [defaultCenter, setDefaultCenter] = useState()
  const [loading, setLoading] = useState(false)


  const errorMsg = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
    });
  };

  useEffect(() => {
    //set user
    const user = UserService.getUserData()
    setUser(user);

    //set center
    const defaultCenter = { lat: -1.286389, lng: 36.817223 };
    setCenter(defaultCenter)
  }, []);

  useEffect(() => {
    if (user && user.latitude && user.longitude) {
      setCenter({
        lat: parseFloat(user.latitude),
        lng: parseFloat(user.longitude),
      });
    }

    console.log(user)
  }, [user]);

  useEffect(() => {
    // if (selectedHobby?.id)
    // {
    fetchActivityByHobby("locations"); // Fetch activities based on hobby and activity type
    // }
  }, [selectedHobby ? selectedHobby.id : null]);

  const fetchActivityByHobby = async (activity) => {
    const token = localStorage.getItem("token");
    if (!token) return;

    let endPoint = "";

    setLoading(true)
    if (flag === "main-map") {
      if (activity === "locations") {
        endPoint = "/user/hobbies/locations";
      } else if (activity === "events") {
        endPoint = "/user/hobbies/events";
      } else {
        endPoint = "/user/hobbies/shops";
      }
    } else {
      if (activity === "locations") {
        endPoint = `/locations/hobby/${selectedHobby.id}`;
      } else if (activity === "events") {
        endPoint = `/events/hobby/${selectedHobby.id}`;
      } else {
        endPoint = `/shops/hobby/${selectedHobby.id}`;
      }
    }

    try {
      const response = await axios.get(`${BASE_URL}${endPoint}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { activities } = response.data;

      setActivities(activities);
      // setCenter({
      //   lat: parseFloat(activities[0]?.latitude || 0),
      //   lng: parseFloat(activities[0]?.longitude || 0),
      // });
      // console.log(activities);
    } catch (error) {
      setActivities([]); // Clear activities on error
      console.error("Error fetching activities:", error);
    } finally {
      setLoading(false)
    }
  };
  // Added dependencies for callback

  const handleFetch = (activityType) => {
    setActiveButton(activityType); // Set the active button
    fetchActivityByHobby(activityType);
  };

  // Create a custom marker using HTML and CSS
  const createCustomMarker = (activity) => {
    return (
      <div className="pin1">
        <img src={activity.hobby.icon} className="marker-image" />
      </div>
    );
  };

  return (
    <div className="map-container">
      {loading && <CenteredLoader loadMessage={"Loading hobbies..."} />}
      <button
        className={activeButton === "locations" ? "active" : ""}
        onClick={() => handleFetch("locations")}
      >
        Fetch Locations
      </button>
      <button
        className={activeButton === "events" ? "active" : ""}
        onClick={() => handleFetch("events")}
      >
        Fetch Events
      </button>
      <button
        className={activeButton === "shops" ? "active" : ""}
        onClick={() => handleFetch("shops")}
      >
        Fetch Shops
      </button>

      <div className="map-wrapper">

        {/* TODO:move key to more secure place */}
        <LoadScript googleMapsApiKey="AIzaSyA_B-qolW1H25WQaB3k3CPGPUnq8n3M46U">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center || defaultCenter}
            zoom={10}
          >
            {activities.map((activity, index) => (
              <OverlayView
                key={index}
                position={{
                  lat: parseFloat(activity.latitude),
                  lng: parseFloat(activity.longitude),
                }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                {createCustomMarker(activity)}
              </OverlayView>
            ))}
          </GoogleMap>

        </LoadScript>
      </div>
    </div>
  );
}

export default MapComponent;
