const UserService = (() => {
    // Function to get user data from localStorage
    const getUserData = () => {
        const userData = localStorage.getItem("user");
        return userData ? JSON.parse(userData) : null;
    };

    // Function to set user data into localStorage after login
    const setUserData = (user) => {
        localStorage.setItem("user", JSON.stringify(user));
    };

    // Function to clear user data (for logout)
    const clearUserData = () => {
        localStorage.removeItem("user");
    };

    return {
        getUserData,
        setUserData,
        clearUserData,
    };
})();

// Make it globally accessible
export default UserService;
