import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import "./SelectHobbyList.css";
import { BASE_URL } from "../../../api/core";
import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/logo_home.png";
import { message } from "antd";

const SelectHobbyList = () => {
  const navigate = useNavigate();
  const observer = useRef();
  
  const [hobbies, setHobbies] = useState([]);
  const [filteredHobbies, setFilteredHobbies] = useState([]);
  const [displayedHobbies, setDisplayedHobbies] = useState([]);
  const [selectedHobbies, setSelectedHobbies] = useState([]);
  const [isHobbiesLoading, setLoadHobbies] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  
  const ITEMS_PER_LOAD = 20; // Number of items to load initially and on each scroll

  // Fetch hobbies from backend
  useEffect(() => {
    const fetchHobbies = async () => {
      const token = localStorage.getItem("token");
      const hideLoading = message.loading("Fetching hobbies...", 0);

      setLoadHobbies(true);

      try {
        const response = await axios.get(`${BASE_URL}/hobbies/user/select`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const { data } = response;
        setHobbies(data.hobbies);
        setFilteredHobbies(data.hobbies);
        setDisplayedHobbies(data.hobbies.slice(0, ITEMS_PER_LOAD));
        setHasMore(data.hobbies.length > ITEMS_PER_LOAD);
      } catch (error) {
        console.error("Error fetching hobbies:", error);
      } finally {
        hideLoading();
        setLoadHobbies(false);
      }
    };

    fetchHobbies();
  }, []);

  // Update displayed hobbies when search term changes
  useEffect(() => {
    const filtered = hobbies.filter((hobby) => 
      hobby.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredHobbies(filtered);
    setDisplayedHobbies(filtered.slice(0, ITEMS_PER_LOAD));
    setPage(1);
    setHasMore(filtered.length > ITEMS_PER_LOAD);
  }, [searchTerm, hobbies]);

  // IntersectionObserver setup for infinite scrolling
  const lastHobbyElementRef = useCallback(node => {
    if (isHobbiesLoading) return;
    if (observer.current) observer.current.disconnect();
    
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        loadMoreHobbies();
      }
    });
    
    if (node) observer.current.observe(node);
  }, [isHobbiesLoading, hasMore]);

  // Load more hobbies function
  const loadMoreHobbies = () => {
    setLoadHobbies(true);
    
    const nextPage = page + 1;
    const startIndex = (nextPage - 1) * ITEMS_PER_LOAD;
    const endIndex = startIndex + ITEMS_PER_LOAD;
    
    // Short timeout to prevent too rapid loading
    setTimeout(() => {
      setDisplayedHobbies(prevHobbies => [
        ...prevHobbies,
        ...filteredHobbies.slice(startIndex, endIndex)
      ]);
      
      setPage(nextPage);
      setHasMore(endIndex < filteredHobbies.length);
      setLoadHobbies(false);
    }, 300);
  };

  const handleSelect = (hobby) => {
    const user = localStorage.getItem("user");
    const parsedUser = JSON.parse(user);

    setSelectedHobbies((prevSelectedHobbies) => {
      const existingHobby = prevSelectedHobbies.find(
        (selected) => selected.hobby_id === hobby.id
      );
      if (existingHobby) {
        return prevSelectedHobbies.filter(
          (selected) => selected.hobby_id !== hobby.id
        );
      } else {
        return [
          ...prevSelectedHobbies,
          { hobby_id: hobby.id, user_id: parsedUser?.id },
        ];
      }
    });
  };

  const handleProceed = () => {
    if (selectedHobbies.length > 0) {
      saveUserHobbies(selectedHobbies);
    } else {
      alert("Please select at least one hobby.");
    }
  };

  const saveUserHobbies = async (selectedHobbies) => {
    const token = localStorage.getItem("token");

    if (!token) return;

    const hideLoading = message.loading("Saving hobbies...", 0);
       
    try {
      await axios.post(
        `${BASE_URL}/hobbies/user`,
        { hobbies: selectedHobbies },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      );

      sendValidationEmail()
      navigate("/validation");
    } catch (error) {
      console.error("Error saving hobbies:", error);
    } finally {
      hideLoading();
    }
  };


  const sendValidationEmail = async () => {
    const token = localStorage.getItem("token");

    if (!token) return;
    try {
      await axios.post(
        `${BASE_URL}/send-validation-email`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      );

    alert("Validation link has been sent to your email")
    } catch (error) {
      console.error("Error saving hobbies:", error);
    } finally {
    }
  };


  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  return (
    <>
      {isHobbiesLoading && displayedHobbies.length === 0 ? null : (
        <div style={styles.container}>
          {/* Left Section */}
          <div style={styles.leftSection}>
            <img src={Logo} alt="Hobbee Logo" style={styles.logo} />
            <h1 style={styles.title}>Hobbee</h1>
            <p style={styles.subtitle}>Pursue Your Passion</p>
          </div>

          {/* Right Section */}
          <div style={styles.rightSection}>
            <div style={styles.header}>
              <h2>Select Hobbies</h2>
              <p>
                Choose at least one hobby and join a vibrant community of
                members who share your passions!
              </p>
              <input
                type="text"
                placeholder="Search hobbies..."
                value={searchTerm}
                onChange={handleSearch}
                style={styles.searchBox}
              />
            </div>
            
            <div style={styles.listContainer}>
              {displayedHobbies.map((hobby, index) => {
                // If this is the last element, attach the ref for infinite scrolling
                if (displayedHobbies.length === index + 1) {
                  return (
                    <div
                      ref={lastHobbyElementRef}
                      key={hobby.id}
                      className={`list-item ${
                        selectedHobbies.find(
                          (selected) => selected.hobby_id === hobby.id
                        )
                          ? "active"
                          : ""
                      }`}
                      onClick={() => handleSelect(hobby)}
                      style={{
                        ...styles.listItem,
                        backgroundColor: selectedHobbies.find(
                          (selected) => selected.hobby_id === hobby.id
                        )
                          ? "#FFD700"
                          : "#FFFFFF",
                      }}
                    >
                      {hobby.name}
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={hobby.id}
                      className={`list-item ${
                        selectedHobbies.find(
                          (selected) => selected.hobby_id === hobby.id
                        )
                          ? "active"
                          : ""
                      }`}
                      onClick={() => handleSelect(hobby)}
                      style={{
                        ...styles.listItem,
                        backgroundColor: selectedHobbies.find(
                          (selected) => selected.hobby_id === hobby.id
                        )
                          ? "#FFD700"
                          : "#FFFFFF",
                      }}
                    >
                      {hobby.name}
                    </div>
                  );
                }
              })}
            </div>
            
            {isHobbiesLoading && displayedHobbies.length > 0 && (
              <div style={styles.loadingIndicator}>Loading more hobbies...</div>
            )}
            
            <button
              onClick={handleProceed}
              style={styles.proceedButton}
              disabled={selectedHobbies.length === 0}
            >
              Proceed
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    minHeight: "100vh",
    fontFamily: "Arial, sans-serif",
  },
  leftSection: {
    flex: 0.3, // 30% of the width
    backgroundColor: "#FFD700", // Yellow background
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    position: "sticky",
    top: 0,
    height: "100vh",
  },
  logo: {
    width: "80px",
    marginBottom: "10px",
  },
  title: {
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  subtitle: {
    fontSize: "16px",
    textAlign: "center",
  },
  rightSection: {
    flex: 0.7, // 70% of the width
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    overflowY: "auto",
    maxHeight: "100vh",
  },
  header: {
    textAlign: "center",
    marginBottom: "20px",
    position: "sticky",
    top: 0,
    backgroundColor: "#FFFFFF",
    zIndex: 10,
    width: "100%",
    padding: "10px 0",
  },
  searchBox: {
    width: "100%",
    maxWidth: "400px",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    marginBottom: "20px",
  },
  listContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
    gap: "10px",
    width: "100%",
    paddingBottom: "20px",
  },
  listItem: {
    padding: "10px",
    borderRadius: "5px",
    cursor: "pointer",
    textAlign: "center",
    transition: "background-color 0.3s",
    border: "1px solid #ccc",
  },
  loadingIndicator: {
    padding: "10px",
    textAlign: "center",
    color: "#666",
  },
  proceedButton: {
    marginTop: "20px",
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#FFD700",
    color: "#000000",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    position: "sticky",
    bottom: "20px",
    alignSelf: "center",
  },
};

export default SelectHobbyList;