"use client";

import { useEffect, useRef, useState } from "react";
import { Car, Bus, Bike, MapPin, Star, Plus, Minus } from "lucide-react";
import { Card } from "antd";
import MapComponent from "../../../components/common/MapComponent/MapComponent";

const styles = {
  container: {
    maxWidth: "100%",
    // margin: "0 auto",
    fontFamily: "system-ui, sans-serif",
  },
  map: {
    width: "100%",
    height: "300px",
    borderRadius: "8px 8px 0 0",
  },
  controls: {
    position: "absolute",
    right: "8px",
    top: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  button: {
    width: "32px",
    height: "32px",
    backgroundColor: "white",
    border: "1px solid #ddd",
    borderRadius: "4px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    padding: "16px",
  },
  header: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    marginBottom: "16px",
  },
  logo: {
    width: "40px",
    height: "40px",
    backgroundColor: "#96bf47",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    margin: "0",
    fontSize: "18px",
    fontWeight: "600",
  },
  location: {
    color: "#666",
    margin: "0",
  },
  address: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "12px 0",
    borderBottom: "1px solid #eee",
  },
  travelTimes: {
    display: "flex",
    gap: "16px",
    marginTop: "12px",
  },
  travelOption: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    color: "#666",
  },
};

export default function FeedMapPage() {
  const mapRef = useRef(null);
  const [mapZoom, setMapZoom] = useState(14);

//   useEffect(() => {
//     if (window.google) {
//       const map = new window.google.maps.Map(mapRef.current, {
//         center: { lat: 45.502, lng: -73.567 }, // Montreal coordinates
//         zoom: mapZoom,
//       });
//     }
//   }, [mapZoom]);

  return (
    <Card style={styles.container}>
          <MapComponent flag={"main-map"} />
      {/* <div style={styles.map} ref={mapRef}></div> */}
      {/* <div style={styles.controls}>
        <button style={styles.button} aria-label="Save location">
          <Star size={16} />
        </button>
        <button
          style={styles.button}
          onClick={() => setMapZoom((prev) => Math.min(prev + 1, 20))}
          aria-label="Zoom in"
        >
          <Plus size={16} />
        </button>
        <button
          style={styles.button}
          onClick={() => setMapZoom((prev) => Math.max(prev - 1, 5))}
          aria-label="Zoom out"
        >
          <Minus size={16} />
        </button>
      </div>

      <div style={styles.content}>
        <div style={styles.header}>
          <div style={styles.logo}>
            <MapPin color="white" size={20} />
          </div>
          <div>
            <h2 style={styles.title}>Shopify</h2>
            <p style={styles.location}>Montreal, Canada</p>
          </div>
        </div>

        <div style={styles.address}>
          <MapPin size={16} />
          <span>490 Rue De la Gauchetière O, Montréal, QC H2Z 1R9</span>
        </div>

        <div style={styles.travelTimes}>
          <div style={styles.travelOption}>
            <Car size={16} />
            <span>18 min by car</span>
          </div>
          <div style={styles.travelOption}>
            <Bus size={16} />
            <span>55 min by public transit</span>
          </div>
          <div style={styles.travelOption}>
            <Bike size={16} />
            <span>1hr 09 min by bike</span>
          </div>
          <div style={styles.travelOption}>
            <span>16.3km</span>
          </div>
        </div>
      </div> */}
    </Card>
  );
}
