import React, { useCallback, useEffect, useState } from "react";
import "./PostSection.css";
import Post from "../layouts/Post/Post";
import { BASE_URL } from "../../api/core";
import axios from "axios";
import { message } from "antd";
import { MdPermMedia } from "react-icons/md";
import { SiFeedly } from "react-icons/si";
import { FaMapMarkedAlt, FaShoppingCart, FaUserFriends, FaListAlt } from "react-icons/fa";
import ImageGallery from "../layouts/ImageGallery/ImageGallery";
import FriendsList from "../common/FriendsList/FriendsList";
import MapComponent from "../common/MapComponent/MapComponent";
import '../common/MapComponent/MapComponent.css'

function PostSection({ selectedHobby, setFeedLength}) {
  const [activeTab, setActiveTab] = useState("posts");
  const [feeds, setFeeds] = useState([]);
  const [showHeader, setShowHeader] = useState(true)

  const fetchFeeds = async () => {
    const hideLoading = message.loading("Loading more feeds...", 0);
    // setLoading(true);

    try {
      const token = localStorage.getItem("token");
      if (!token) return;

      const response = await axios.get(
        `${BASE_URL}/feeds/user-hobbie/${selectedHobby?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          // params: { page },
        }
      );


      const { feeds, auth_user_friends } = response.data;
      setFeeds(feeds);
      setFeedLength(feeds?.length)

      console.log(feeds)
    } catch (error) {
      console.log(error);
      message.error("Error fetching data: " + error);
    } finally {
      hideLoading();
      // setLoading(false);
    }
  };


  // Fetch initial data
  useEffect(() => {
    fetchFeeds();

  }, [selectedHobby?.id, activeTab]);

  const handlePost = () => {
    // Add post logic
  };

  return (
    <div className="post-section">
      <div className="tabs">
      <button
          className={`tab ${activeTab === "posts" ? "active" : ""}`}
          onClick={() => setActiveTab("posts")}
        >
         <FaListAlt />{" "} <span>Posts</span>
        </button>
        <button
          className={`tab ${activeTab === "media" ? "active" : ""}`}
          onClick={() => setActiveTab("media")}
        >
          <MdPermMedia />{" "}<span>Media</span>
        </button>
        <button
          className={`tab ${activeTab === "friends" ? "active" : ""}`}
          onClick={() => setActiveTab("friends")}
        >
          <FaUserFriends /> {" "} <span>Friends</span>
        </button>
        <button
          className={`tab ${activeTab === "map" ? "active" : ""}`}
          onClick={() => setActiveTab("map")}
        >
          <FaMapMarkedAlt />{" "}<span>Map</span>
        </button>
        <button
          className={`tab ${activeTab === "cart" ? "active" : ""}`}
          onClick={() => setActiveTab("cart")}
        >
          <FaShoppingCart /> {" "} <span>Cart</span>
        </button>
      </div>

      {activeTab === "posts" && (
        <div className="post-input-section">
          <ul style={{marginTop:58}}>
            {feeds?.map((feed, index) => {
              return (
                <li key={index}>
                  <Post feed={feed} setShowHeader={setShowHeader} />
                </li>
              );
            })}
          </ul>
        </div>
      )}


      {activeTab === "media" && (
        <div className="about-section">
         <ImageGallery feeds={feeds}/>
        </div>
      )}

      {activeTab === "friends" && (
        <div className="settings-section">
         <FriendsList selectedHobby={selectedHobby}/>
        </div>
      )}

      {activeTab === "map" && (
        <div className="settings-section">
          <MapComponent flag={"hobby-map"}     selectedHobby={selectedHobby} />
        </div>
      )}
        {activeTab === "cart" && (
        <div className="settings-section">
        <p>This feature is not available in your area.</p>
        </div>
      )}
    </div>
  );
}

export default PostSection;
