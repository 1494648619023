// ReferralDashboard.js
import React, { useEffect, useState } from 'react';
import { Card, Button, Input, Table, Tag, Tabs, Statistic, ConfigProvider, Avatar, Modal, message } from 'antd';
import {
  CopyOutlined,
  CheckOutlined,
  TwitterOutlined,
  FacebookOutlined,
  FilterOutlined,
  DownloadOutlined,
  ArrowUpOutlined,
  CrownOutlined,
  DollarOutlined
} from '@ant-design/icons';
import './ReferralDashboard.css';
import NavigationHeader from '../../../../components/common/NavigationHeader/NavigationHeader';
import LeftSideBar from '../../../../components/layouts/Sidebar/LeftSideBar/LeftSideBar';
import axios from 'axios';
import { BASE_URL } from '../../../../api/core';
import UserService from '../../../../services/UserService';
import CenteredLoader from '../../../../components/common/Loader/CenteredLoader';
import Helper from '../../../../helpers/Helper';
import { useNavigate } from 'react-router-dom';

const ReferralDashboard = () => {
  const navigate = useNavigate();

  const [copied, setCopied] = useState(false);
  const [activeLayout, setActiveLayout] = useState("posts")
  const [referralStats, setReferralStats] = useState(null);
  const [referralEarnings, setReferralEarnings] = useState(null);
  const [referralClicks, setReferralClicks] = useState(null)
  const [referralLink, setRefferalLink] = useState("");
  const [referralCode, setRefferalCode] = useState("");
  const [referredUsers, setReferredUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [checkoutModalVisible, setCheckoutModalVisible] = useState(false);
  const [readyForPayout, setReadyForPayout] = useState(false)

  // Define columns for the Ant Design Table
  const columns = [
    {
      title: "Profile",
      dataIndex: "image_url",
      key: "image_url",
      render: (image) => <Avatar src={image} size="large" />,
    },
    {
      title: "Full Name",
      key: "fullName",
      render: (_, record) => `${record.firstname || "no name"} ${record.lastname || ""}`, // Combine first & last name
    },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   key: "email",
    // },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Time Ago",
      dataIndex: "time_ago",
      key: "time_ago",
    },
  ];

  // Convert API data into table format
  // const referredUsers = referredUsersData.referred_users.data.map((user) => ({
  //   key: user.id,
  //   fullName: `${user.firstname} ${user.lastname}`,
  //   email: user.email,
  //   referral_code: user.referral_code,
  //   time_ago: user.time_ago,
  //   image_url: user.image_url,
  // }));


  const tabItems = [
    { key: 'all', label: 'All' },
    { key: 'pending', label: 'Pending' },
    { key: 'confirmed', label: 'Confirmed' },
    { key: 'paid', label: 'Paid' },
  ];

  useEffect(() => {
    const checkStatus = async () => {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };

      try {
        const res = await axios.get(`${BASE_URL}/stripe/check-payout-status`, { headers });
        if (res.data.ready_for_payout) {
          setReadyForPayout(true)
          console.log('✅ User is ready to receive payouts');
        } else {
          setReadyForPayout(false)
          console.log('⛔ Not ready for payouts yet:', res.data.status);
        }
      } catch (err) {
        console.error('Error checking payout status', err);
      }
    };

    checkStatus();
  }, []);


  useEffect(() => {
    const user = UserService.getUserData();
    setUser(user)

    fetchReferralData(user)
  }, [])


  const fetchReferralData = async (user) => {

    const token = localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`
    };

    if (!token) return;

    setLoading(true);
    setError(null);

    console.log(user)

    try {
      const [statsResponse, usersResponse, clicksResponse] = await axios.all([
        axios.get(`${BASE_URL}/referral/stats`, { headers }),
        axios.get(`${BASE_URL}/referral/users`, { headers }),
        axios.get(`${BASE_URL}/referral/clicks/${user?.id}`, { headers })
      ]);


      const { stats, earnings, referral_code, referral_link } = statsResponse.data;
      const { referred_users } = usersResponse.data;
      const { referral_clicks } = clicksResponse.data;

      setReferralStats(stats);
      setReferralEarnings(earnings)
      setRefferalLink(`https://hobbee.fun/#/registration/${referral_code}`)
      setRefferalCode(referral_code)
      setReferredUsers(referred_users.data);
      setReferralClicks(referral_clicks)


    } catch (error) {
      console.error("Error fetching referral data", error);
      setError("Failed to load referral data.");
    } finally {
      setLoading(false);
    }
  };


  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  // Handle Stripe checkout
  const handleCheckout = async () => {
    if (!referralEarnings || referralEarnings.total_earnings <= 0) {
      message.warning("You don't have any earnings to withdraw");
      return;
    }

    setCheckoutModalVisible(true);
  };

  const confirmCheckout = async () => {

    if (readyForPayout) {
      console.log('✅ User is ready to receive payouts');
      return;
    }
    try {
      const token = localStorage.getItem("token");

      const headers = {
        Authorization: `Bearer ${token}`
      };
      setCheckoutLoading(true);

      // Create stripe accouunt then transfer cash to it in the backend
      const response = await axios.post(`${BASE_URL}/create-account`, {
        amount: referralEarnings.total_earnings,
      }, { headers });


      if (response.data.onboarding_url) {
        const encodedUrl = encodeURIComponent(response.data.onboarding_url);
        navigate(`/onboarding/redirect-to-stripe?url=${encodedUrl}`);
      }
    } catch (error) {
      console.error("Error creating checkout session", error);
      message.error("Failed to process checkout. Please try again later.");
    } finally {
      setCheckoutLoading(false);
      setCheckoutModalVisible(false);
    }
  };

  const cancelCheckout = () => {
    setCheckoutModalVisible(false);
  };

  return (
    <div className="referral-dashboard-container">
      {loading && <CenteredLoader loadMessage={"Loading, please wait..."} />}
      <NavigationHeader />
      <div style={{ display: "flex", gap: 40 }}>
        <div className={"left-sidebar"} style={{ position: "relative", top: 0 }}>
          <LeftSideBar
            setActiveLayout={setActiveLayout}
            fromPage={"refferals"}
            showProfile={true}
            showHobbies={false} />
        </div>

        <div>
          {/* Stats Section */}
          <div className="stats-grid">
            <Card>
              <Statistic
                title="Total Earnings"
                value={!referralEarnings ? 0 : Helper.currencyFormatter(referralEarnings?.total_earnings)}
                suffix={
                  <div className="stat-change">
                    {/* <ArrowUpOutlined /> {referralEarnings?.total_earnings}% */}
                    {/* <div className="change-label">from last month</div> */}
                  </div>
                }
              />
              <Button
                type="primary"
                icon={<DollarOutlined />}
                className="cashout-button"
                onClick={handleCheckout}
                disabled={!referralEarnings || referralEarnings.total_earnings <= 0}
                style={{ marginTop: '16px', backgroundColor: '#ffd700', borderColor: '#ffd700', color: "#000" }}
              >
                Cash Out with Stripe
              </Button>
            </Card>

            <Card>
              <Statistic
                title="Total Referrals"
                value={referralStats?.total_referrals}
                suffix={
                  <div className="stat-change">
                    <ArrowUpOutlined /> 0%
                    <div className="change-label">from last month</div>
                  </div>
                }
              />
            </Card>

            <Card>
              <Statistic
                title="Conversion Rate"
                value={`${referralClicks?.conversion_rate || ""}%`}
                suffix={
                  <div className="stat-change">
                    <ArrowUpOutlined /> 0
                    <div className="change-label">from last month</div>
                  </div>
                }
              />
            </Card>
          </div>

          {/* Share Section */}
          <Card title="Share Your Referral Link" className="share-section">
            <div className="link-container">
              <Input value={`${referralLink}`} readOnly />
              <Button className='copy-btn' type="primary" onClick={copyToClipboard} icon={copied ? <CheckOutlined /> : <CopyOutlined />}>
                {copied ? 'Copied!' : 'Copy'}
              </Button>
            </div>
            <div className="social-buttons">
              <Button type="primary" icon={<TwitterOutlined />} className="twitter-button">
                Share on Twitter
              </Button>
              <Button type="primary" icon={<FacebookOutlined />} className="facebook-button">
                Share on Facebook
              </Button>
            </div>
          </Card>

          {/* Referrals Section */}
          <Card
            title="Recent Referrals"
            extra={
              <div className="table-actions">
                <Button icon={<FilterOutlined />}>Filter</Button>
                <Button icon={<DownloadOutlined />}>Export</Button>
              </div>
            }
          >
            <ConfigProvider
              theme={{
                components: {
                  Tabs: {
                    itemActiveColor: "#ffd700", // Change active tab text color
                    itemSelectedColor: "#ffd700", // Change selected tab color
                    inkBarColor: "#ffd700", // Change underline color
                  },
                  Button: {
                    colorPrimary: "#ff5733", // Primary button background color
                    colorPrimaryHover: "#ffd700", // Hover color
                    colorPrimaryActive: "#ffd700", // Active (clicked) color
                    colorPrimaryBorder: "#ffd700", // Border color
                  },
                },
              }}
            >
              <Tabs items={tabItems} />
            </ConfigProvider>

            <Table
              columns={columns}
              dataSource={referredUsers}
              rowKey="id"
              loading={loading}
              pagination={{ pageSize: 10 }}
            />
          </Card>

          {/* Checkout Modal */}
          <Modal
            title="Cash Out Confirmation"
            open={checkoutModalVisible}
            onOk={confirmCheckout}
            onCancel={cancelCheckout}
            confirmLoading={checkoutLoading}
            okText={readyForPayout ? "Transfer funds to you bank" : "Proceed to Stripe"}
            cancelText="Cancel"
            okButtonProps={{
              style: {
                backgroundColor: "#ffd700", // Change background color
                borderColor: "#ffd700", // Change border color
                color: "#000", // Text color

              },
            }}
          >
            {readyForPayout ?
              <>
                <p>You're all set to receive payments!</p>
                <p>Funds will be transferred to your Stripe account, and Stripe will automatically deposit them into your bank account according to their payout schedule.</p>
              </>
              :
              <>
                <p>Start receiving payments by registering with Stripe.</p>
                <p>You’ll be redirected to Stripe to complete your onboarding. Once finished, you’ll be able to receive payments from us directly.</p>
              </>
            }
          </Modal>

          {/* Leaderboard Section */}
          {/* <Card
            title={
              <span className="leaderboard-title">
                <CrownOutlined className="crown-icon" /> Top Influencers
              </span>
            }
          >
            {leaderboard.map((leader, index) => (
              <div key={leader.key} className="leaderboard-item">
                <div className="leader-info">
                  <div className="leader-rank">{index + 1}</div>
                  <div>
                    <div className="leader-name">{leader.name}</div>
                    <div className="leader-stats">{leader.referrals.toLocaleString()} referrals</div>
                  </div>
                </div>
                <div className="leader-earnings">${leader.earnings.toLocaleString()}</div>
              </div>
            ))}
          </Card> */}
        </div>
      </div>
    </div>
  );
};

export default ReferralDashboard;