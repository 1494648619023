import "./ScheduleTab.css";

const ScheduleTab = () => {
  const events = [
    {
      title: "Live Coding Session: Building a 3D Portfolio",
      date: "Next Tuesday, 2:00 PM EST",
      type: "Live Stream",
    },
    {
      title: "React Advanced Patterns Workshop",
      date: "Friday, 1:00 PM EST",
      type: "Workshop",
    },
    {
      title: "Code Review & Q&A",
      date: "Sunday, 11:00 AM EST",
      type: "Community Event",
    },
  ];

  return (
    <div className="upcoming-content-container">
      <div className="card">
        <h3 className="card-title">Upcoming Content</h3>
        <div className="event-list">
          {events.map((event) => (
            <div key={event.title} className="event-item">
              <div>
                <h4 className="event-title">{event.title}</h4>
                <span className="event-type">{event.type}</span>
              </div>
              <div className="event-date">{event.date}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScheduleTab;
