import React, { useContext, useEffect, useState } from "react";
import "./Avatar.css";
import ProfilePic from "../../../assets/watch-movie.png";
import { UserContext } from "../../../context";

function Avatar({
  showUserDetails,
  mainStyle,
  userDetailsLayoutHeight,
  borderWidth,
  width,
  height,
  textSize,
  subTextSize,
  
}) {

  const {userGlobal} = useContext(UserContext)


  return (
    <div
      className="profile-pic"
      style={mainStyle}
    >
      <img src={userGlobal?.image_url} style={{ width: width, height: height,   objectFit: 'cover' }} />
      {showUserDetails && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ height: userDetailsLayoutHeight }}>
            <h3 className="name" style={{ fontSize: textSize }}>
              {userGlobal?.firstname}{" "}{userGlobal?.lastname}
            </h3>
            <p className="username" style={{ fontSize: subTextSize }}>
              @{userGlobal?.username}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Avatar;
